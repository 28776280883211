import { render, staticRenderFns } from "./ProfileHeaderCard.vue?vue&type=template&id=5cfe60d6&scoped=true&"
import script from "./ProfileHeaderCard.vue?vue&type=script&lang=js&"
export * from "./ProfileHeaderCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cfe60d6",
  null
  
)

export default component.exports