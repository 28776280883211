<template>
  <div class="container merchant">
    <div class="div-block-28">
      <div class="profileheaderblock">
        <div class="div-block-27">
          <img
            src="~@/assets/images/Male_1.png"
            loading="lazy"
            width="90.5"
            alt=""
          />
        </div>
      </div>
      <div class="div-block-30">
        <div class="merchant-profile-name-copy">
          <div class="text-block-25-copy">{{ merchant.merchantName }} <VerifiedCard /></div>
          <div>
            <a href="#" class="followbtn w-button">Follow</a>
          </div>
        </div>
        <div class="profiledetails">
          <div class="div-block-31">
            <div class="text-block-26">
              0 <span class="text-span">Followers</span>
            </div>
            <div class="text-block-26">
              0 <span class="text-span">Following</span>
            </div>
            <div class="text-block-26">
              {{ products.length }}
              <span class="text-span">Products</span>
            </div>
          </div>
        </div>
        <div class="merchantbio">
          <div class="text-block-27">
            Lorem ipsum dolor sit amhjdjsj. Neque turpis lorem a ipsum dolor sit
            amhjdjsj.  ipsum dolor sit amhjdjsj.  ipsum dolor sit amhjdjsj.
          </div>
        </div>
        <div class="merchantcontactdetails">
          <div class="email">
            <div class="text-block-28">{{ merchant.merchantEmail }}</div>
          </div>
          <div class="email">
            <div class="text-block-28">{{ merchant.merchantPhone }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VerifiedCard from "@/components/cards/verified/VerifiedCard";

export default {
  name: "ProfileHeaderCard",
  components: { VerifiedCard },
  computed: {
    ...mapGetters({
      merchant: "merchant/getCurrentMerchant",
      products: "merchant/getMerchantProducts",
    }),
  },
  methods: {},
  created() {},
};
</script>

<style scoped></style>
