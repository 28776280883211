export const namespaced = true;

export const state = {
  isMenuOpen: false,
  windowWidth: window.innerWidth,
};

export const getters = {
  getIsMenuOpen: (state) => {
    return state.isMenuOpen;
  },
  getWindowWidth: (state) => {
    return state.windowWidth;
  },
};

export const mutations = {
  TOGGLE_SIDE_MENU(state) {
    state.isMenuOpen = !state.isMenuOpen;
  },
  SET_WINDOW_WIDTH(state) {
    state.windowWidth = window.innerWidth;
  },
};

export const actions = {};
