<template>
  <div class="product-header">
    <div class="product-image">
      <div class="div-block-37">
        <a
          href="#"
          class="lightbox-link w-inline-block w-lightbox"
          v-for="image in product.pictures"
          :key="image.id"
        >
          <img
            :src="image.url"
            loading="lazy"
            width="46"
            alt=""
            class="image-23"
          />
        </a>
      </div>

      <div class="mask w-slider-mask">
        <Swiper ref="mySwiper" :options="swiperOptions">
          <SwiperSlide
            v-for="src in pictures.map((picture) => picture.path)"
            :key="src"
          >
            <img
              :src="src"
              loading="lazy"
              alt=""
              class="image-18"
              style="border-radius: 5px"
            />
          </SwiperSlide>
          <div class="swiper-pagination" slot="pagination"></div>
        </Swiper>
      </div>
      <div class="left-arrow w-slider-arrow-left"></div>
      <div class="right-arrow w-slider-arrow-right"></div>
      <div class="slide-nav w-slider-nav w-round"></div>
    </div>
    <div class="div-block-38">
      <div class="text-block-33">
        {{ product.productName }}
      </div>

      <div class="text-block-34">NGN {{ product.productPrice }}</div>
      <div class="div-block-39">
        <div class="div-block-40">
          <div class="text-block-35">Quantity:</div>
        </div>
        <div class="div-block-41">
          <a @click="change('minus')" class="minus-button w-button"></a>
          <div>{{ quantity }}</div>
          <a @click="change('add')" class="add-button w-button"></a>
        </div>
      </div>
      <div class="div-block-42">
        <a href="#" class="buy-now-btn w-button">Buy now</a>
        <a @click="addToCart(product)" class="add-to-cart-btn w-button">Add to cart</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import StoreUtils from "@/utils/baseUtils/StoreUtils";

export default {
  name: "ProductDetailsCard",
  components: { Swiper, SwiperSlide },
  data(){
    return{
      quantity: 1,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProductDetails",
      pictures: "product/getCurrentProductDetailsPictures",
    }),
  },
  methods: {
    addToCart: function(payload){
      const cart = {
        id: payload.id,
        name: payload.productName,
        quantity: this.quantity,
        price: payload.productPrice,
        pictures: payload.pictures.map( img => {return {imageUrl : img.url}}),
      };
      StoreUtils.dispatch("product/addCartItem", cart);
    },
    change: function(direction){
      if (this.quantity === 1 && direction === "minus") return;
      direction === "add" ? this.quantity++ : this.quantity--;
    }
  }
};
</script>

<style scoped></style>
