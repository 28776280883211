import Vue from "vue";
import Vuex from "vuex";

// BASE MODULES
import * as router from "./modules/baseModules/router.js";
import * as loader from "./modules/baseModules/loader.js";
import * as notification from "./modules/baseModules/notification.js";
import * as form from "./modules/baseModules/form.js";
import * as table from "./modules/baseModules/table.js";
import * as utils from "./modules/baseModules/utils.js";
import * as sideMenu from "./modules/baseModules/sideMenu.js";
import * as modal from "./modules/baseModules/modal.js";

import * as auth from "./modules/auth.js";
import * as user from "./modules/user.js";
import * as post from "./modules/post.js";
import * as merchant from "./modules/merchant.js";
import * as product from "./modules/product.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    product,
    merchant,
    post,
    user,
    auth,

    router,
    loader,
    notification,
    form,
    table,
    utils,
    sideMenu,
    modal,
  },
  state: {},
  mutations: {},
  actions: {},
});
