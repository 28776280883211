<template>
  <div class="categories-sidebar">
    <p class="title">Categories</p>

    <div
      v-for="category in productCategories"
      :key="category.categoryId"
      class="value"
    >
      <p v-if="category.categoryId === currentPostCategoryId" class="cate active">
        <span>
          <img
            :src="`https://assets-base.s3.amazonaws.com/img/afropay/categories/${category.categoryId}.png`"
            alt=""
            class="ico"
          />
        </span>
        {{ category.categoryName }}
      </p>
      <p v-else class="cate" @click="fetchProductsInThisCategory(category)">
        <span>
          <img
            :src="`https://assets-base.s3.amazonaws.com/img/afropay/categories/${category.categoryId}.png`"
            alt=""
            class="ico"
          />
        </span>
        {{ category.categoryName }}
      </p>
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import RouterUtils from "@/utils/baseUtils/RouterUtils";

export default {
  name: "CategoriesSidebarCard",
  computed: {
    productCategories() {
      return StoreUtils.rootGetters("post/getProductCategories");
    },
    currentPostCategoryId() {
      return StoreUtils.rootGetters("post/getCurrentPostCategoryId");
    },
  },
  methods: {
    fetchProductsInThisCategory(category) {
      StoreUtils.commit(
        "post/SET_CURRENT_POST_CATEGORY_ID",
        category.categoryId
      );
      RouterUtils.changeRouteUrlTo(`/post/category/${category.categoryId}`);
    },
  },
};
</script>

<style scoped></style>
