// import UserServices from "../../services/UserServices";

// const userServices = new UserServices();

const getDefaultState = () => {
  return {
    userInfo: {
      merchantId: "104",
      customerId: "1",
      customerEmail: "james.titus@gmail.com",
      s3Key: {
        accessKey: "AKIAQCMYLE2YMMH2BU7J",
        secretKey: "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",
      },
    },
  };
};

export const namespaced = true;

export const state = getDefaultState();

export const getters = {
  getUserInfo: (state) => {
    return state.userInfo;
  },
  getUserID: (state) => {
    return state.userInfo.userId;
  },
  getMerchantId: (state) => {
    return state.userInfo.merchantId;
  },
  getCustomerId: (state) => {
    return state.userInfo.customerId;
  },
  getCustomerEmail: (state) => {
    return state.userInfo.customerEmail;
  },
  getS3Key: (state) => {
    return state.userInfo.s3Key;
  },
};

export const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = { ...state.userInfo, payload };
  },
};

export const actions = {};
