<template>
  <div class="app-layout">
    <TheHeader />

    <div class="page-content">
      <slot />
    </div>

    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "../the/TheHeader";
import TheFooter from "@/components/app/the/TheFooter";
export default {
  name: "AppLayout",
  components: { TheFooter, TheHeader },
};
</script>

<style scoped>
.app-layout {
}
.app-layout .app-header {
}
.app-layout .page-content {
}
.app-layout .app-footer {
}
</style>
