import ProductService from "../../services/ProductService";
import StoreUtils from "../../utils/baseUtils/StoreUtils";
import LoaderUtils from "../../utils/baseUtils/LoaderUtils";
import NotificationUtils from "@/utils/baseUtils/NotificationUtils";
import store from "@/store/store";

const productService = new ProductService();

export const namespaced = true;
const getDefaultState = () => {
  return {
    currentPaymentContract: {},
    topProducts: [],
    relatedProducts: [],
    currentProductDetails: {},
    currentProductId: "",
    cart: [],
    currentCart: {}
  };
};

export const state = getDefaultState();

export const getters = {
  getCurrentPaymentContract: (state) => {
    return state.currentPaymentContract;
  },
  getCurrentCart: (state) => {
    return state.currentCart;
  },
  getCartProducts: (state) => {
    console.log(state.cart);
    console.log(localStorage.getItem("cart"));
    state.cart = JSON.parse(localStorage.getItem("cart"));
    return state.cart
  },
  getSingleCart: (state) => (productId) => {
    return state.cart.find(o => o.id === productId)
  },
  getCartAmount: (state, getters) => {
    return getters.getCartProducts
      .map((it) => parseFloat(it.price) * it.quantity)
      .reduce((prev, curr) => prev + curr, 0);
  },
  getTopProducts: (state) => {
    return state.topProducts;
  },
  getRelatedProducts: (state) => {
    return state.relatedProducts;
  },
  getCurrentProductDetails: (state) => {
    return state.currentProductDetails;
  },
  getCurrentProductDetailsPictures: (state) => {
    return (
      state.currentProductDetails.pictures &&
      state.currentProductDetails.pictures.map((i) => {
        return { caption: i.caption, path: i.url };
      })
    );
  },
  getCurrentProductId: (state) => {
    return state.currentProductId;
  },
};

export const mutations = {
  SET_CURRENT_PAYMENT_CONTRACT(state, payload) {
    state.currentPaymentContract = payload;
  },
  SET_CURRENT_CART(state, payload) {
    state.currentcart = payload;
  },
  ADD_CART(state, payload) {
    state.cart.push(payload);
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  REMOVE_CART(state, payload) {
    state.cart = state.cart.filter((pr) => pr.id !== payload.id);
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  SET_CURRENT_PRODUCT_ID(state, payload) {
    state.currentProductId = payload;
  },
  SET_TOP_PRODUCTS(state, payload) {
    state.topProducts = payload;
  },
  SET_RELATED_PRODUCTS(state, payload) {
    state.relatedProducts = payload;
  },
  SET_CURRENT_PRODUCT_DETAILS(state, payload) {
    state.currentProductDetails = {
      ...state.currentProductDetails,
      ...payload,
    };
  },
};

export const actions = {
  generatePaymentContract(undefined, { productId }) {
    let payload = {
      productId,
      customerId: StoreUtils.rootGetters(
        StoreUtils.getters.user.GET_CUSTOMER_ID
      ),
    };
    StoreUtils.commit("modal/SHOW_PAYMENT_MODAL", true);

    let SuccessAction = (responseData) => {
      StoreUtils.commit("product/SET_CURRENT_PAYMENT_CONTRACT", responseData);
    };
    return productService.generatePaymentContract(
      payload,
      SuccessAction,
      LoaderUtils.types.COMPONENT
    );
  },
  addCartItem(state, payload){
    const localProduct = localStorage.getItem("cart");
    console.log(JSON.parse(localProduct));
    if (localProduct || !Array.isArray(localProduct))
      localStorage.setItem("cart", "[]");
    if (
      JSON.parse(localProduct)
        .map((it) => it.id)
        .includes(payload.id)
    ) {
      NotificationUtils.addNotificationSlide(
        "item already added",
        NotificationUtils.type.ERROR
      );
      return;
    }
    store.commit("product/ADD_CART", payload);
    NotificationUtils.addNotificationSlide(
      "item added",
      NotificationUtils.type.SUCCESS
    );
  },
  fetchProductDetails(state, payload) {
    let SuccessAction = (responseData) => {
      console.log("details", responseData);
      let data = {
        id: responseData.id,
        productCurrency: responseData.productCurrency,
        productDate: responseData.productDate,
        productDescription: responseData.productDescription,
        productMerchantId: responseData.productMerchantId,
        productName: responseData.productName,
        productPrice: responseData.productPrice,
        productQuantity: responseData.productQuantity,
        pictures: responseData.pictures,
        categories: responseData.categories,
      };
      StoreUtils.commit("product/SET_CURRENT_PRODUCT_DETAILS", data);
    };
    console.log(payload);
    return productService.fetchProductDetails(
      payload,
      SuccessAction,
      LoaderUtils.types.BLOCKING
    );
  },
  change(state, { direction, item }) {
    const itemToIncrement = store.getters["product/getSingleCart"](item.id);
    if (itemToIncrement.quantity === 1 && direction === "minus") return;
    direction === "add" ? itemToIncrement.quantity++ : itemToIncrement.quantity--;
    localStorage.setItem("cart", JSON.stringify(this.cart));
  },
  fetchRelatedProducts(undefined, { productId }) {
    let payload = {
      productId,
    };
    let SuccessAction = (responseData) => {
      StoreUtils.commit("product/SET_RELATED_PRODUCTS", responseData.data);
    };
    return productService.fetchRelatedProducts(
      payload,
      SuccessAction,
      LoaderUtils.types.BLOCKING
    );
  },
  fetchTopProducts() {
    let SuccessAction = (responseData) => {
      StoreUtils.commit("product/SET_TOP_PRODUCTS", responseData.data);
    };
    return productService.fetchTopProducts(
      {},
      SuccessAction,
      LoaderUtils.types.BLOCKING
    );
  },
};
