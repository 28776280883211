<template>
  <div class="w-form">
    <FormulateForm v-model="formBody" @submit="submit">
      <FormulateInput
        name="otp"
        validation="required"
        placeholder="OTP"
        type="text"
      />
      <FormulateInput type="submit" label="Submit" />
    </FormulateForm>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../utils/baseUtils/StoreUtils";

export default {
  name: "OTPForm",
  data() {
    return {
      formBody: {
        otp: "",
        mobileNumber: this.currentFormBody.mobileNumber,
      },
    };
  },
  computed: {
    ...mapState(["auth"]),
    currentFormBody() {
      return StoreUtils.rootGetters("form/getFormBody");
    },
  },
  methods: {
    submit() {
      StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);
      StoreUtils.dispatch("auth/registerComplete");
    },
    toLogin() {},
  },
  created() {
    // const mobileNumber = this.currentFormBody.mobileNumber;
    // StoreUtils.dispatch("auth/sendOTP", { mobileNumber });
  },
};
</script>

<style scoped></style>
