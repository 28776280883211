<template>
  <MerchantLayout>
    <div class="container merchant">
      <div class="div-block-28">
        <div class="profileheaderblock">
          <div class="div-block-27"><img src="images/Male_1.png" loading="lazy" width="90.5" alt=""></div>
        </div>
        <div class="div-block-30">
          <div class="merchant-profile-name">
            <div class="text-block-25-copy">Aliu Muibi-Hammed</div>
            <div>
              <a href="#" class="w-inline-block"><img src="images/Settings.svg" loading="lazy" alt=""></a>
            </div>
          </div>
          <div class="profiledetails">
            <div class="div-block-31-copy">
              <div class="text-block-26-copy">7657 <span class="text-span">Followers</span></div>
              <div class="text-block-26">594 <span class="text-span">Following</span></div>
            </div>
          </div>
          <div class="merchantbio">
            <div class="text-block-27"></div>
          </div>
          <div class="merchantcontactdetails">
            <div class="email">
              <div class="text-block-28">adeyemi.julius@gmail.com</div>
            </div>
            <div class="phone">
              <div class="text-block-28">+2347654789954</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container merchant-profile">
      <div data-current="Tab 5" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
        <div class="tabs-menu-2 w-tab-menu">
          <a data-w-tab="Tab 3" class="profile-tabs w-inline-block w-tab-link" :class="walletactive ? 'w--current' : ''" @click="swapPane('wallet')">
            <div>WALLET</div><img loading="lazy" src="images/wallet.svg" alt="" class="image-25">
          </a>
          <a data-w-tab="Tab 4" class="profile-tabs w-inline-block w-tab-link"  :class="ordersactive ? 'w--current' : ''" @click="swapPane('orders')">
            <div>ORDERS</div><img loading="lazy" src="images/receipt.svg" alt="" class="image-25">
          </a>
          <a data-w-tab="Tab 5" class="profile-tabs w-inline-block w-tab-link"  :class="wishlistactive ? 'w--current' : ''" @click="swapPane('wishlist')">
            <div>WISHLIST</div><img loading="lazy" src="images/receipt-text.svg" alt="" class="image-25">
          </a>
        </div>
        <div class="w-tab-content">
          <div data-w-tab="Tab 3" class="tab-pane-tab-3 w-tab-pane" :class="walletactive ? 'w--tab-active' : ''">
            <div class="wallet-section-wrapper">
              <div class="left-pane">
                <div class="div-block-50">
                  <div class="text-block-42">Your Wallet Balance</div>
                  <h1 class="heading-14">₦ 0.00</h1>
                  <a href="#" class="button-5 w-button">Fund wallet</a>
                </div>
                <div class="div-block-51">
                  <div class="div-block-52">
                    <div class="text-block-44">Totals</div>
                    <div class="text-block-43">Request Statement</div>
                  </div>
                  <div class="div-block-53">
                    <div class="div-block-54">
                      <div class="text-block-45">Spent</div>
                    </div>
                    <div>
                      <div class="text-block-47">₦ 0.00</div>
                    </div>
                  </div>
                  <div class="div-block-53-copy">
                    <div class="div-block-54-copy">
                      <div class="text-block-46">Received</div>
                    </div>
                    <div>
                      <div class="text-block-48">₦ 0.00</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-pane">
                <div class="text-block-42-copy">Recent Transactions</div>
                <div class="div-block-55"><img loading="lazy" src="images/Transaction.svg" alt="" class="image-26">
                  <div>You have no recent transactions</div>
                </div>
                <div class="div-block-56">
                  <div class="div-block-57">
                    <div class="div-block-58"><img loading="lazy" src="images/Arrow-right_2.svg" alt=""></div>
                    <div>
                      <div>Wallet Funded</div>
                      <div class="text-block-49">+ ₦ 0.00</div>
                    </div>
                  </div>
                  <div>
                    <div>31st Aug 2021</div>
                  </div>
                </div>
                <div class="div-block-56">
                  <div class="div-block-57">
                    <div class="div-block-58"><img loading="lazy" src="images/Arrow-right_2.svg" alt=""></div>
                    <div>
                      <div>Wallet Funded</div>
                      <div class="text-block-49">+ ₦ 0.00</div>
                    </div>
                  </div>
                  <div>
                    <div>31st Aug 2021</div>
                  </div>
                </div>
                <div class="div-block-56">
                  <div class="div-block-57">
                    <div class="div-block-58"><img loading="lazy" src="images/Arrow-right_2.svg" alt=""></div>
                    <div>
                      <div>Wallet Funded</div>
                      <div class="text-block-49">+ ₦ 0.00</div>
                    </div>
                  </div>
                  <div>
                    <div>31st Aug 2021</div>
                  </div>
                </div>
                <div class="div-block-56">
                  <div class="div-block-57">
                    <div class="div-block-58"><img loading="lazy" src="images/Arrow-right_2.svg" alt=""></div>
                    <div>
                      <div>Wallet Funded</div>
                      <div class="text-block-49">+ ₦ 0.00</div>
                    </div>
                  </div>
                  <div>
                    <div>31st Aug 2021</div>
                  </div>
                </div>
                <div class="div-block-56">
                  <div class="div-block-57">
                    <div class="div-block-58"><img loading="lazy" src="images/Arrow-right_2.svg" alt=""></div>
                    <div>
                      <div>Wallet Funded</div>
                      <div class="text-block-49">+ ₦ 0.00</div>
                    </div>
                  </div>
                  <div>
                    <div>31st Aug 2021</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-w-tab="Tab 4" class="w-tab-pane" :class="ordersactive ? 'w--tab-active' : ''">
            <div class="div-block-59">
              <div class="div-block-61"><img loading="lazy" src="images/Rectangle-437.png" alt="" class="image-27">
                <div class="div-block-67">
                  <div class="text-block-50">Knight&#x27;s Fortress Men&#x27;s Cotton Long Sleeve Shirt In Orange Check + Casual shirt &amp; 4 items</div>
                  <div class="text-block-51">Order #54675577</div>
                  <div class="complete">
                    <div class="completed-text">COMPLETED</div>
                  </div>
                </div>
              </div>
              <div class="div-block-61"><img loading="lazy" src="images/Rectangle-437.png" alt="" class="image-27">
                <div class="div-block-67">
                  <div class="text-block-50">Knight&#x27;s Fortress Men&#x27;s Cotton Long Sleeve Shirt In Orange Check + Casual shirt &amp; 4 items</div>
                  <div class="text-block-51">Order #54675577</div>
                  <div class="pending-sign">
                    <div class="completed-text">PENDING</div>
                  </div>
                </div>
              </div>
              <div class="div-block-61"><img loading="lazy" src="images/Rectangle-437.png" alt="" class="image-27">
                <div class="div-block-67">
                  <div class="text-block-50">Knight&#x27;s Fortress Men&#x27;s Cotton Long Sleeve Shirt In Orange Check + Casual shirt &amp; 4 items</div>
                  <div class="text-block-51">Order #54675577</div>
                  <div class="cancelled-label">
                    <div class="completed-text">CANCELLED</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-block-55-copy"><img loading="lazy" src="images/Transaction.svg" alt="" class="image-26">
              <div>You have no recent transactions</div>
            </div>
          </div>
          <div data-w-tab="Tab 5" class="w-tab-pane" :class="wishlistactive ? 'w--tab-active' : ''">
            <div class="div-block-59">
              <div class="div-block-60">
                <div class="div-block-61-copy"><img loading="lazy" src="images/Rectangle-437.png" alt="" class="image-27">
                  <div class="div-block-63">
                    <div class="text-block-50">Knight&#x27;s Fortress Men&#x27;s Cotton Long Sleeve Shirt In Orange Check + Casual shirt</div>
                    <div class="text-block-51-copy">₦ 45,500.00</div>
                  </div>
                </div>
                <div class="div-block-62">
                  <div>
                    <a href="#" class="link-9-copy">Remove</a>
                  </div>
                </div>
              </div>
              <div class="div-block-60">
                <div class="div-block-61-copy"><img loading="lazy" src="images/Rectangle-437.png" alt="" class="image-27">
                  <div class="div-block-63">
                    <div class="text-block-50">Knight&#x27;s Fortress Men&#x27;s Cotton Long Sleeve Shirt In Orange Check + Casual shirt</div>
                    <div class="text-block-51-copy">₦ 45,500.00</div>
                  </div>
                </div>
                <div class="div-block-62">
                  <div>
                    <a href="#" class="link-9-copy">Remove</a>
                  </div>
                </div>
              </div>
              <div class="div-block-60">
                <div class="div-block-61-copy"><img loading="lazy" src="images/Rectangle-437.png" alt="" class="image-27">
                  <div class="div-block-63">
                    <div class="text-block-50">Knight&#x27;s Fortress Men&#x27;s Cotton Long Sleeve Shirt In Orange Check + Casual shirt</div>
                    <div class="text-block-51-copy">₦ 45,500.00</div>
                  </div>
                </div>
                <div class="div-block-62">
                  <div>
                    <a href="#" class="link-9-copy">Remove</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-block-55-copy"><img loading="lazy" src="images/Transaction.svg" alt="" class="image-26">
              <div>You have no wishlist</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MerchantLayout>
</template>

<script>
import MerchantLayout from "@/components/app/layout/MerchantLayout";
export default {
  name: "MyAccount",
  data(){
    return{
      walletactive: true,
      ordersactive: false,
      wishlistactive: false,
    }
  },
  methods:{
    swapPane: function(pane){
      this.walletactive = false;
      this.ordersactive = false;
      this.wishlistactive = false;
      if (pane === "wallet") this.walletactive = true;
      if (pane === "orders") this.ordersactive = true;
      if (pane === "wishlist") this.wishlistactive = true;
    }
  },
  components: { MerchantLayout },
};
</script>

<style scoped>

</style>
