<template>
  <div class="comments-wrap">
    <!-- Header -->
    <div class="comments-heading">
      <h4>
        Comments <small>({{ currentPost.totalCommentCount }})</small>
      </h4>
      <div
        @click="closePostComments(currentPost)"
        class="close-comments is-active"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </div>
    <!-- /Header -->

    <!-- Comments body -->
    <div class="comments-body has-slimscroll">
      <!-- Comment -->
      <div
        v-for="comment in currentPost.lastTenComments"
        :key="comment.id"
        class="media is-comment"
      >
        <!-- User image -->
        <div class="media-left">
          <div class="image">
            <img
              src="https://via.placeholder.com/300x300"
              data-demo-src="assets/img/avatars/dan.jpg"
              data-user-popover="1"
              alt=""
            />
          </div>
        </div>
        <!-- Content -->
        <div class="media-content">
          <a href="#">{{ comment.customerId }}</a>
          <span class="time">{{ comment.timestamp | formatDateRel }}</span>
          <p>
            {{ comment.comment }}
          </p>
          <!-- Actions -->
          <div class="controls">
            <div class="like-count">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-thumbs-up"
              >
                <path
                  d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"
                ></path>
              </svg>
              <span>4</span>
            </div>
            <div class="reply">
              <a href="#">Reply</a>
            </div>
            <div class="edit">
              <a href="#">Edit</a>
            </div>
          </div>
        </div>
        <!-- Right side dropdown -->
        <div class="media-right">
          <!-- /partials/pages/feed/dropdowns/comment-dropdown.html -->
          <div class="dropdown is-spaced is-right is-neutral dropdown-trigger">
            <div>
              <div class="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-more-vertical"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
                </svg>
              </div>
            </div>
            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a class="dropdown-item">
                  <div class="media">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                    <div class="media-content">
                      <h3>Hide</h3>
                      <small>Hide this comment.</small>
                    </div>
                  </div>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                  <div class="media">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-flag"
                    >
                      <path
                        d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"
                      ></path>
                      <line x1="4" y1="22" x2="4" y2="15"></line>
                    </svg>
                    <div class="media-content">
                      <h3>Report</h3>
                      <small>Report this comment.</small>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Comment -->
    </div>
    <!-- /Comments body -->

    <!-- Comments footer -->
    <div class="card-footer">
      <div class="media post-comment has-emojis">
        <!-- Comment Textarea -->
        <div class="media-content">
          <div class="field">
            <p class="control">
              <textarea
                class="textarea comment-textarea"
                rows="5"
                placeholder="Write a comment..."
                id="post-comment-textarea-1"
                v-model="comment"
              ></textarea>
            </p>
          </div>
          <!-- Additional actions -->
          <div class="actions">
            <div class="image is-32x32">
              <img
                class="is-rounded"
                src="https://via.placeholder.com/300x300"
                data-demo-src="assets/img/avatars/jenna.png"
                data-user-popover="0"
                alt=""
              />
            </div>
            <div class="toolbar">
              <div class="action is-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-at-sign"
                >
                  <circle cx="12" cy="12" r="4"></circle>
                  <path
                    d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"
                  ></path>
                </svg>
              </div>
              <div class="action is-emoji" id="post-comment-button-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-smile"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                </svg>
              </div>
              <div class="action is-upload">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-camera"
                >
                  <path
                    d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"
                  ></path>
                  <circle cx="12" cy="13" r="4"></circle>
                </svg>
                <input type="file" />
              </div>
              <a @click="commentPost" class="app-btn"
                >Post Comment
                <transition v-if="actionButtonLoader" name="fade">
                  <i class="fa fa-spinner fa-spin"></i>
                </transition>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Comments footer -->
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";

export default {
  name: "PostCommentCard",
  props: {
    currentPost: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    actionButtonLoader() {
      return StoreUtils.rootGetters("loader/getActionButtonLoader");
    },
  },
  methods: {
    commentPost() {
      StoreUtils.dispatch("post/commentPost", { comment: this.comment }).then(
        (response) => {
          if (response.responseCode === "00") {
            this.comment = "";
            this.closePostComments();
          }
        }
      );
    },
    closePostComments() {
      StoreUtils.commit("post/SET_OPENED_COMMENT_POST_ID", "");
    },
  },
};
</script>

<style scoped></style>
