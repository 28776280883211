export const namespaced = true;

export const state = {
  activeRoute: "home",
};

export const getters = {
  getActiveRoute: (state) => {
    return state.activeRoute;
  },
};

export const mutations = {
  SET_ACTIVE_ROUTE(state, payload) {
    state.activeRoute = payload;
  },
};
export const actions = {
  setActiveRoute({ commit }, payload) {
    commit("SET_ACTIVE_ROUTE", payload);
  },
};
