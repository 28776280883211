<template>
  <div class="w-form">
    <FormulateForm @submit="login">
      <div class="div-block">
        <FormulateInput
          name="username"
          validation="required"
          input-class="text-field w-input"
          placeholder="Username"
          type="text"
        />
      </div>

      <FormulateInput
        type="password"
        name="password"
        input-class="text-field w-input"
        validation="required"
        placeholder="Password"
      />

      <div class="div-block">
        <a @click="toForgotPassword" class="forgot-password-link w-inline-block">
          <div class="text-block-5">Forgot password?</div>
        </a>
      </div>
      <FormulateInput type="submit" label="Login" />
    </FormulateForm>

  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../utils/baseUtils/StoreUtils";
import RouterUtils from "../../../utils/baseUtils/RouterUtils";

export default {
  name: "LoginForm",
  data() {
    return {
      formBody: {},
    };
  },
  computed: {
    ...mapState(["auth"]),
  },
  methods: {
    login(data) {
      StoreUtils.commit("form/BUILD_FORM_BODY", data);
      StoreUtils.dispatch("auth/login");
      // StoreUtils.dispatch("auth/login", this.formBody);
    },
    toForgotPassword() {
      RouterUtils.changeRouteTo(RouterUtils.routes.auth.FORGOT_PASSWORD);
    },
    toRegister() {
      RouterUtils.changeRouteTo(RouterUtils.routes.auth.REGISTER);
    },
  },
};
</script>

<style  lang="scss">

</style>
