<template>
  <MerchantLayout>
    <div class="merchant-section wf-section">
      <div class="container merchant">
        <div>
          <div class="text-block-21">Browse merchants</div>
        </div>
        <div class="div-block-20"></div>
      </div>
      <AllMerchantCards />
    </div>
  </MerchantLayout>
</template>

<script>
import MerchantLayout from "@/components/app/layout/MerchantLayout";
import AllMerchantCards from "@/components/cards/merchant/AllMerchantCards";
export default {
  name: "AllMerchants",
  components: { AllMerchantCards, MerchantLayout },
};
</script>

<style scoped></style>
