<template>
  <MerchantLayout>
    <div class="container cart">
      <div class="cart-wrapper">
        <CartContentCard />
        <CartTotalCard />
      </div>
      <SuggestedProductCard />
    </div>
  </MerchantLayout>
</template>

<script>
import MerchantLayout from "@/components/app/layout/MerchantLayout";
import CartContentCard from "@/components/cards/cart/CartContentCard";
import CartTotalCard from "@/components/cards/cart/CartTotalCard";
import SuggestedProductCard from "@/components/cards/product/SuggestedProductCard";
export default {
  name: "Cart",
  components: { SuggestedProductCard, CartTotalCard, CartContentCard, MerchantLayout }
};
</script>

<style scoped>

</style>
