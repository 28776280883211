<template>
  <app-layout>
    <div class="feeds">
      <div class="columns">
        <div class="column is-3 is-hidden-mobile">
          <CategoriesSidebarCard />
        </div>
        <div class="column is-9">
          <PostsByCategoryTable />
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import PostsByCategoryTable from "@/components/tables/post/PostsByCategoryTable";
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import CategoriesSidebarCard from "@/components/cards/post/CategoriesSidebarCard";
import AppLayout from "@/components/app/layout/AppLayout";
export default {
  name: "PostByCategory",
  components: { AppLayout, CategoriesSidebarCard, PostsByCategoryTable },
  methods: {
    fetchPostsByCategory() {
      StoreUtils.dispatch("post/fetchPostsByCategory", {
        categoryId: StoreUtils.rootGetters("post/getCurrentPostCategoryId"),
      });
    },
  },
  created() {
    this.fetchPostsByCategory();
  },
};
</script>

<style scoped></style>
