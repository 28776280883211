<template>
  <div class="content-wrap post-card">
    <!-- Post header -->
    <div class="card-heading">
      <!-- User meta -->
      <div class="user-block">
        <div class="image">
          <img
            :src="`${currentPost.postOwner.merchantProfilePicture}`"
            data-demo-src="assets/img/avatars/dan.jpg"
            data-user-popover="1"
            alt=""
          />
        </div>
        <div class="user-info">
          <a @click="goToMerchantProfile(currentPost.postOwner)"
            >{{ currentPost.postOwner.merchantName }}
            <span class="is-verified"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  d="M7 0.4C7.1 0.2 7.3 0.1 7.5 0.1 7.7 0 7.9 0 8.1 0 8.3 0.1 8.5 0.2 8.6 0.4 8.7 0.6 8.8 0.8 8.8 1 8.8 1.2 8.8 1.3 8.9 1.5 8.9 1.6 9 1.7 9.2 1.8 9.3 1.9 9.4 2 9.6 2 9.7 2 9.9 2 10.1 1.9 10.3 1.9 10.5 1.9 10.7 1.9 10.9 2 11 2.1 11.2 2.3 11.3 2.5 11.4 2.7 11.4 2.9 11.4 3.1 11.3 3.3 11.2 3.5 11.1 3.6 11 3.7 11 3.9 10.9 4 11 4.2 11 4.3 11.1 4.5 11.1 4.6 11.3 4.7 11.4 4.9 11.5 4.9 11.7 5 11.9 5 12 5.2 12.2 5.3 12.3 5.5 12.4 5.7 12.4 5.9 12.4 6.1 12.3 6.3 12.2 6.5 12 6.7 11.9 6.8 11.7 6.8 11.5 6.9 11.4 7 11.3 7.1 11.1 7.2 11.1 7.3 11 7.5 11 7.6 10.9 7.8 11 7.9 11 8.1 11.1 8.3 11.2 8.4 11.3 8.5 11.4 8.7 11.4 9 11.4 9.2 11.3 9.4 11.2 9.5 11 9.7 10.9 9.8 10.7 9.9 10.5 10 10.3 10 10.1 9.9 9.9 9.8 9.7 9.8 9.6 9.8 9.4 9.9 9.3 9.9 9.2 10 9 10.1 8.9 10.2 8.9 10.4 8.8 10.5 8.8 10.7 8.8 10.8 8.8 11 8.7 11.2 8.6 11.4 8.5 11.6 8.3 11.7 8.1 11.8 7.9 11.9 7.7 11.8 7.5 11.8 7.3 11.7 7.1 11.6 7 11.4 6.9 11.3 6.8 11.2 6.6 11.1 6.5 11 6.3 11 6.2 11 6 11 5.9 11 5.7 11.1 5.6 11.2 5.5 11.3 5.4 11.4 5.3 11.6 5.1 11.7 4.9 11.8 4.7 11.8 4.5 11.9 4.3 11.8 4.1 11.7 3.9 11.6 3.8 11.4 3.7 11.2 3.6 11 3.6 10.8 3.6 10.7 3.6 10.5 3.5 10.4 3.4 10.2 3.3 10.1 3.2 10 3.1 9.9 2.9 9.9 2.8 9.8 2.6 9.8 2.4 9.8 2.3 9.9 2.1 10 1.9 10 1.7 9.9 1.5 9.8 1.3 9.7 1.2 9.5 1.1 9.4 1 9.2 1 9 1 8.7 1.1 8.5 1.2 8.4 1.3 8.3 1.4 8.1 1.4 7.9 1.4 7.8 1.4 7.6 1.4 7.5 1.3 7.3 1.2 7.2 1.1 7.1 1 7 0.8 6.9 0.7 6.8 0.5 6.8 0.3 6.7 0.2 6.5 0.1 6.3 0 6.1 0 5.9 0 5.7 0.1 5.5 0.2 5.3 0.3 5.2 0.5 5 0.7 5 0.8 4.9 1 4.9 1.1 4.7 1.2 4.6 1.3 4.5 1.4 4.3 1.4 4.2 1.4 4 1.4 3.9 1.4 3.7 1.3 3.6 1.2 3.5 1.1 3.3 1 3.1 1 2.9 1 2.7 1.1 2.5 1.2 2.3 1.3 2.1 1.5 2 1.7 1.9 1.9 1.9 2.1 1.9 2.3 1.9 2.4 2 2.6 2 2.8 2 2.9 2 3.1 1.9 3.2 1.8 3.3 1.7 3.4 1.6 3.5 1.5 3.6 1.3 3.6 1.2 3.6 1 3.6 0.8 3.7 0.6 3.8 0.4 3.9 0.2 4.1 0.1 4.3 0 4.5 0 4.7 0 4.9 0.1 5.1 0.1 5.3 0.2 5.4 0.4 5.5 0.6 5.6 0.7 5.7 0.7 5.9 0.8 6 0.8 6.2 0.8 6.3 0.8 6.5 0.8 6.6 0.7 6.8 0.7 6.9 0.6 7 0.4Z"
                  fill="#0F4FAA"
                />
                <path
                  d="M9.3 4.4L8.8 3.9C8.8 3.9 8.7 3.9 8.7 3.9 8.7 3.9 8.7 3.9 8.7 3.9 8.6 3.9 8.6 3.9 8.6 3.9L5.2 7 4 5.9C4 5.8 4 5.8 3.9 5.8 3.9 5.8 3.9 5.8 3.9 5.9L3.4 6.3C3.4 6.3 3.3 6.3 3.3 6.3 3.3 6.3 3.3 6.4 3.3 6.4 3.3 6.4 3.3 6.4 3.3 6.4 3.3 6.4 3.4 6.4 3.4 6.4L4.9 7.8C5 7.9 5.1 7.9 5.2 8 5.4 7.9 5.5 7.9 5.6 7.8L9.3 4.5C9.3 4.5 9.3 4.5 9.3 4.5 9.3 4.4 9.3 4.4 9.3 4.4Z"
                  fill="white"
                /></svg></span
          ></a>
        </div>
      </div>
      <!-- Right side dropdown -->
      <!-- /partials/pages/feed/dropdowns/feed-post-dropdown.html -->
      <div class="dropdown is-spaced is-right is-neutral dropdown-trigger">
        <div>
          <div class="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-more-vertical"
            >
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="12" cy="5" r="1"></circle>
              <circle cx="12" cy="19" r="1"></circle>
            </svg>
          </div>
        </div>
        <div class="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <a href="#" class="dropdown-item">
              <div class="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-bookmark"
                >
                  <path
                    d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
                  ></path>
                </svg>
                <div class="media-content">
                  <h3>Bookmark</h3>
                  <small>Add this post to your bookmarks.</small>
                </div>
              </div>
            </a>
            <a class="dropdown-item">
              <div class="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-bell"
                >
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg>
                <div class="media-content">
                  <h3>Notify me</h3>
                  <small>Send me the updates.</small>
                </div>
              </div>
            </a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item">
              <div class="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-flag"
                >
                  <path
                    d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"
                  ></path>
                  <line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>
                <div class="media-content">
                  <h3>Flag</h3>
                  <small>In case of inappropriate content.</small>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- /Post header -->

    <!-- Post body -->
    <div class="card-body">
      <!-- Post body text -->
      <div class="post-text">
        <p>
          {{ currentPost.postMessage }}
        </p>
      </div>
      <!-- Featured image -->
      <PostImageCard :pictures="currentPost.pictures" />
    </div>
    <!-- /Post body -->

    <!-- Post footer -->
    <div class="post-footer">
      <div class="blck">
        <div class="price">
          <p class="title">price</p>
          <p class="sub">₦ {{ currentPost.price | nairaAmountFilter }}</p>
        </div>
      </div>
      <div class="blck">
        <!-- Post statistics -->
        <div class="social-count">
          <div
            @click="likePost(currentPost)"
            class="likes-count"
            :class="{ liked: currentPost.postLiked }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-heart"
            >
              <path
                d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
              ></path>
            </svg>
            <span>27</span>
          </div>
          <!--        <div class="shares-count">-->
          <!--          <svg-->
          <!--            xmlns="http://www.w3.org/2000/svg"-->
          <!--            width="24"-->
          <!--            height="24"-->
          <!--            viewBox="0 0 24 24"-->
          <!--            fill="none"-->
          <!--            stroke="currentColor"-->
          <!--            stroke-width="2"-->
          <!--            stroke-linecap="round"-->
          <!--            stroke-linejoin="round"-->
          <!--            class="feather feather-link-2"-->
          <!--          >-->
          <!--            <path-->
          <!--              d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"-->
          <!--            ></path>-->
          <!--            <line x1="8" y1="12" x2="16" y2="12"></line>-->
          <!--          </svg>-->
          <!--          <span>9</span>-->
          <!--        </div>-->
          <div @click="openPostComments(currentPost)" class="comments-count">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-message-circle"
            >
              <path
                d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
              ></path>
            </svg>
            <span>{{ currentPost.totalCommentCount }}</span>
          </div>
        </div>
      </div>

      <div class="blck">
        <p class="time">{{ currentPost.timestamp | formatDateRel }}</p>
      </div>

      <div class="blck">
        <!-- Followers text -->
        <div class="likers-text">
          <p>
            <a href="#">Similar Posts</a>
          </p>
          <p>23 posts like this</p>
        </div>
      </div>
    </div>
    <!-- /Post footer -->
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import PostImageCard from "@/components/cards/post/PostImageCard";
import RouterUtils from "@/utils/baseUtils/RouterUtils";

export default {
  name: "PostCard",
  props: {
    currentPost: {
      type: Object,
      required: true,
    },
  },
  components: {
    PostImageCard,
  },
  methods: {
    goToMerchantProfile(merchant) {
      StoreUtils.commit("merchant/SET_CURRENT_MERCHANT", merchant);

      RouterUtils.changeRouteUrlTo(`/merchant/profile/${merchant.merchantId}`);
    },
    likePost(post) {
      StoreUtils.commit("post/SET_CURRENT_POST_ID", post.postId);
      StoreUtils.dispatch("post/likePost");
    },
    openPostComments(post) {
      StoreUtils.commit("post/SET_OPENED_COMMENT_POST_ID", post.postId);
    },
  },
};
</script>

<style scoped></style>
