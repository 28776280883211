import PostService from "../../services/PostService";
import StoreUtils from "../../utils/baseUtils/StoreUtils";
import LoaderUtils from "../../utils/baseUtils/LoaderUtils";
import Utils from "@/utils/Utils";
import DateUtils from "@/utils/baseUtils/DateUtils";

const postService = new PostService();

let comparePostTimestampAsc = (a, b) => {
  if (a.timestamp < b.timestamp) return 1;
  if (a.timestamp > b.timestamp) return -1;
  return 0;
};

export const namespaced = true;

const getDefaultState = () => {
  return {
    currentSearchKeyword: "",
    currentPostCategoryId: "",
    allProductCategories: [],
    allPosts: [],
    currentPostId: "",
    openedCommentPostId: "",
    followSuggestions: [],
  };
};
export const state = getDefaultState();

export const getters = {
  getCurrentSearchKeyword: (state) => {
    return state.currentSearchKeyword;
  },
  getCurrentPostCategoryName: (state) => {
    const categoryName = state.allProductCategories.filter(
      (category) => category.categoryId === state.currentPostCategoryId
    )[0];
    console.log(categoryName);
    return categoryName;
  },
  getCurrentPostCategoryId: (state) => {
    return state.currentPostCategoryId;
  },
  getProductCategories: (state) => {
    return state.allProductCategories;
  },
  getAllPosts: (state) => {
    return state.allPosts;
  },
  getCurrentPostId: (state) => {
    return state.currentPostId;
  },
  getOpenedCommentPostId: (state) => {
    return state.openedCommentPostId;
  },
  getFollowSuggestions: (state) => {
    return state.followSuggestions;
  },
};

export const mutations = {
  SET_CURRENT_SEARCH_KEYWORD(state, payload) {
    state.currentSearchKeyword = payload;
  },
  SET_CURRENT_POST_CATEGORY_ID(state, payload) {
    state.currentPostCategoryId = payload;
  },
  SET_ALL_PRODUCT_CATEGORIES(state, payload) {
    state.allProductCategories = payload;
  },
  SET_ALL_POSTS(state, payload) {
    state.allPosts = payload;
  },
  SET_CURRENT_POST_ID(state, payload) {
    state.currentPostId = payload;
  },
  LIKE_A_POST(state, postId) {
    state.allPosts.filter((post) => post.postId === postId)[0].postLiked = true;
    state.allPosts.filter((post) => post.postId === postId)[0].likeCount += 1;
  },
  SET_OPENED_COMMENT_POST_ID(state, postId) {
    state.openedCommentPostId = postId;
  },
  COMMENT_ON_A_POST(state, { postId, comment }) {
    state.allPosts
      .filter((post) => post.postId === postId)[0]
      .lastTenComments.push(comment);
  },
  SET_FOLLOW_SUGGESTIONS(state, payload) {
    state.followSuggestions = payload;
  },
};

export const actions = {
  fetchPostsByCategory(undefined, { categoryId }) {
    let payload = {
      categoryId,
      source: "WEB",
    };
    let successAction = (responseData) => {
      StoreUtils.commit(
        "table/SET_TABLE_DATA",
        responseData.data.sort(comparePostTimestampAsc)
      );
    };

    return postService.fetchPostsByCategory(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      null,
      false
    );
  },
  searchForPosts(undefined, { keyword }) {
    let payload = {
      keyword,
      source: "WEB",
    };
    let successAction = (responseData) => {
      StoreUtils.commit(
        "post/SET_ALL_POSTS",
        responseData.data.sort(comparePostTimestampAsc)
      );
    };

    return postService.searchForPosts(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      null,
      false
    );
  },
  fetchFollowSuggestions() {
    let payload = {};
    let successAction = (responseData) => {
      StoreUtils.commit("post/SET_FOLLOW_SUGGESTIONS", responseData.data);
    };

    return postService.fetchFollowSuggestions(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      null,
      false
    );
  },

  commentPost(undefined, { comment }) {
    let payload = {
      postId: StoreUtils.rootGetters("post/getOpenedCommentPostId"),
      customerId: StoreUtils.rootGetters("user/getCustomerId"),
      type: "comment",
      comment,
    };

    let successAction = (responseData) => {
      const newComment = {
        id: Utils.generateNineRandomNumbers(),
        comment: comment,
        customerId: StoreUtils.rootGetters("user/getCustomerId"),
        timestamp: DateUtils.generateDateTimeLocal(),
      };
      StoreUtils.commit("post/COMMENT_ON_A_POST", {
        postId: StoreUtils.rootGetters("post/getOpenedCommentPostId"),
        comment: newComment,
      });
      return responseData;
    };

    return postService.commentPost(
      payload,
      successAction,
      LoaderUtils.types.ACTION_BUTTON_LOADER,
      null,
      false
    );
  },
  likePost() {
    let payload = {
      postId: StoreUtils.rootGetters("post/getCurrentPostId"),
      customerId: StoreUtils.rootGetters("user/getCustomerId"),
      type: "like",
      comment: "",
    };

    let successAction = () => {
      StoreUtils.commit(
        "post/LIKE_A_POST",
        StoreUtils.rootGetters("post/getCurrentPostId")
      );
    };

    return postService.likePost(
      payload,
      successAction,
      LoaderUtils.types.NONE,
      null,
      false
    );
  },
  fetchAllPosts() {
    let payload = {
      customerId: "0",
      source: "WEB",
    };
    let successAction = (responseData) => {
      StoreUtils.commit(
        "post/SET_ALL_POSTS",
        responseData.data.sort(comparePostTimestampAsc)
      );
    };

    return postService.fetchAllPosts(
      payload,
      successAction,
      LoaderUtils.types.TABLE,
      null,
      false
    );
  },
  fetchAllProductCategories() {
    let payload = {};
    let successAction = (responseData) => {
      StoreUtils.commit("post/SET_ALL_PRODUCT_CATEGORIES", responseData.data);
    };

    return postService.fetchAllProductCategories(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      null,
      false
    );
  },
};
