<template>
  <div class="banner_two_section wf-section">
    <div class="container banner2"></div>
  </div>
</template>

<script>
export default {
  name: "MerchantBanner2",
};
</script>

<style scoped>

</style>
