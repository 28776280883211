import { render, staticRenderFns } from "./SuggestionsCard.vue?vue&type=template&id=655a79ff&scoped=true&"
import script from "./SuggestionsCard.vue?vue&type=script&lang=js&"
export * from "./SuggestionsCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655a79ff",
  null
  
)

export default component.exports