<template>
  <div class="container merchant-profile">
    <div class="did-you-forget-something">
      <div class="header-copy">
        <div class="text-block-29">Merchants Product</div>
      </div>
      <div class="products">
        <div class="w-layout-grid grid-5">
          <div
            class="product-card-wrapper"
            v-for="product in products"
            :key="product.id"
          >
            <div class="div-block-34">
              <img
                :src="product.pictures[0] && product.pictures[0].url"
                loading="lazy"
                style="max-height: 190px; border-radius: 5px"
                alt=""
              />
            </div>
            <div class="div-block-35">
              <div class="text-block-30">
                {{ product.productName }}
              </div>
              <div class="text-block-31">NGN {{ product.productPrice }}</div>
            </div>
            <div class="div-block-35">
              <router-link
                :to="{
                  name: 'product-profile',
                  replace: true,
                  params: { productId: product.id },
                }"
                class="button-3 w-button"
                >View product</router-link
              >
            </div>
          </div>
        </div>
        <div class="next-button-container w-clearfix">
          <a href="#" class="next-button w-button">Next</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MerchantProductCard",
  computed: {
    ...mapGetters({
      products: "merchant/getMerchantProducts",
    }),
  },
};
</script>

<style scoped></style>
