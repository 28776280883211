<template>
  <div>
    <TopNav />
    <slot />
    <TheNewFooter />
    <BottomNav />
  </div>
</template>

<script>
import TopNav from "@/components/app/nav/TopNav";
import BottomNav from "@/components/app/nav/BottomNav";
import TheNewFooter from "@/components/app/the/TheNewFooter";
export default {
  name: "MerchantLayout",
  components: { TheNewFooter, BottomNav, TopNav },
};
</script>

<style scoped></style>
