import StoreUtils from "./StoreUtils";
import router from "../../router/router";

class RouterUtils {
  static routes = {
    auth: {
      LOGIN: "login",
      REGISTER: "register",
      FORGOT_PASSWORD: "forgot-password",
      CHANGE_PASSWORD: "change-password",
    },
    merchant: {
      MERCHANT_PROFILE: "merchant-profile",
      ALL_MERCHANTS: "merchant-all",
    },
    post: {
      POST_BY_CATEGORY: "post-by-category",
      POST_SEARCH: "post-search",
      SHOP_FEEDS: "post-shop-feeds",
    },
    product: {
      PRODUCT_PROFILE: "product-profile",
    },
    HOME: "home",
  };
  static changeBaseRouteTo(targetRoute) {
    StoreUtils.dispatch(StoreUtils.actions.router.setActiveRoute, targetRoute);
    router.push({
      name: targetRoute,
    });
  }

  static changeRouteTo(targetRoute) {
    router.push({
      name: targetRoute,
    });
  }
  static changeActivePageTo(targetRoute) {
    StoreUtils.dispatch(StoreUtils.actions.router.setActiveRoute, targetRoute);
  }
  static changeRouteUrlTo(targetUrl) {
    router.push(targetUrl);
  }
}
export default RouterUtils;
