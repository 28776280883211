<template>
  <div class="">
    <RegisterForm v-if="formStage === 0" />
    <OTPForm v-if="formStage === 1" />
  </div>
</template>

<script>
import RegisterForm from "@/components/forms/auth/RegisterForm";
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import OTPForm from "@/components/forms/auth/OTPForm";
export default {
  name: "RegisterFormCard",
  components: { OTPForm, RegisterForm },
  computed: {
    formStage() {
      return StoreUtils.rootGetters("form/getFormStage");
    },
  },
};
</script>

<style scoped></style>
