<template>
  <div class="feed-wrapper">
    <PostModal />
    <div v-for="post in this.posts" :key="post.id" class="feeds-container">
      <div class="merchant-title">
        <img
          src="https://via.placeholder.com/35"
          loading="lazy"
          width="40"
          alt=""
          class="image-17"
        />
        <a
          @click="openMerchant(post.postOwner.merchantId)"
          class="text-block-16"
        >
          {{ post.postOwner.merchantName }}</a
        >
      </div>
      <router-link
        :to="{
          name: 'product-profile',
          replace: true,
          params: { productId: post.productId },
        }"
      >
        <div class="image-wrapper">
          <Swiper ref="mySwiper" :options="swiperOptions">
            <SwiperSlide
              v-for="src in post.pictures.map((picture) => picture.imageUrl)"
              :key="src"
            >
              <img
                :src="src"
                loading="lazy"
                alt=""
                class="image-18"
                style="border-radius: 5px"
              />
            </SwiperSlide>
            <div class="swiper-pagination" slot="pagination"></div>
          </Swiper>
        </div>
      </router-link>
      <div class="comment-and-amount-wrapper">
        <div>
          <a href="#" class="like-button w-inline-block"
            ><img
              src="~@/assets/images/heart.svg"
              loading="lazy"
              alt=""
              class="image-19"
          /></a>
          <a
            data-w-id="a9defe3b-c96a-9df1-8eb4-29ca81912ab9"
            href="#"
            class="comment-button w-inline-block"
            ><img src="~@/assets/images/comment.svg" loading="lazy" alt=""
          /></a>
        </div>
        <div>
          <div class="text-block-17">
            ₦ {{ post.price | nairaAmountFilter }}
          </div>
        </div>
      </div>
      <div class="feeds-details">
        <div class="text-block-18">23 likes</div>
        <div class="text-block-20">
          {{ post.postMessage }}
        </div>
        <div class="text-block-19">2 HOURS AGO</div>
      </div>
      <a href="#" class="buy-now-button w-button">Buy now</a>
      <button
        href="#"
        @click="addCart(post)"
        class="add-to-cart-button w-button"
      >
        Add to cart
      </button>
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters } from "vuex";
import PostModal from "@/components/modals/PostModal";
import router from "@/router/router";

export default {
  name: "NewPostCard",
  components: { PostModal, Swiper, SwiperSlide },
  computed: {
    ...mapGetters({
      posts: "post/getAllPosts",
      cart: "product/getCartProducts",
    }),
  },
  data() {
    return {
      product: {},
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      sliderValue: 2,
    };
  },
  methods: {
    openMerchant(merchantId) {
      console.log("merchant", merchantId);
      router.push({
        name: "merchant-profile",
        params: { merchantId: merchantId },
      });
    },
    addCart(post) {
      const payload = {
        id: post.productId,
        name: post.postMessage,
        quantity: 1,
        price: post.price,
        pictures: post.pictures,
      };
      StoreUtils.dispatch("product/addCartItem", payload);
    },
  },
  created() {
    StoreUtils.dispatch("post/fetchAllPosts");
  },
};
</script>

<style scoped></style>
