<template>
  <MerchantLayout>
    <div class="merchant-section-copy wf-section">
      <ProfileHeaderCard />
      <MerchantProductCard />
      <SimilarMerchantCard />
    </div>
  </MerchantLayout>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import MerchantLayout from "@/components/app/layout/MerchantLayout";
import ProfileHeaderCard from "@/components/cards/merchant/ProfileHeaderCard";
import MerchantProductCard from "@/components/cards/product/MerchantProductCard";
import SimilarMerchantCard from "@/components/cards/merchant/SimilarMerchantCard";
export default {
  name: "MerchantProfile",
  components: { SimilarMerchantCard, MerchantProductCard, ProfileHeaderCard, MerchantLayout },
  computed: {
    currentMerchant() {
      return StoreUtils.rootGetters("merchant/getCurrentMerchant");
    },
    merchantProducts() {
      return StoreUtils.rootGetters("merchant/getMerchantProducts");
    },
  },
  created() {
    const merchantId = this.$route.params.merchantId;
    this.fetchTableData(merchantId);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchTableData(merchantId) {
      console.log("payload", { merchantId });
      StoreUtils.dispatch("merchant/fetchMerchantDetails", { merchantId });
      StoreUtils.dispatch("merchant/fetchMerchantProducts", { merchantId });
      StoreUtils.dispatch("merchant/fetchSimilarMerchants", { merchantId });
    },
  },
};
</script>

<style scoped></style>
