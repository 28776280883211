<template>
  <div class="banner-section wf-section">
    <div class="container banner">
      <div class="div-block-4">
        <h1 class="heading-2">The best Products<br>in every category</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MerchantBanner",
};
</script>

<style scoped>

</style>
