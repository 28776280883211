<template>
  <div class="categories-section wf-section">
    <div class="container categories">
      <div class="categories">
        <div class="header-wrapper">
          <h1 class="heading-3">Categories</h1>
          <a href="#" class="view-all-button w-inline-block">
            <div class="text-block-7">View all</div>
          </a>
        </div>
        <div class="category-wrapper">
          <div>
            <div class="w-layout-grid grid">
              <a id="w-node-_031c19da-24f8-0010-c18d-2e717aa2780c-f21314e9" href="#" class="link-block-8 w-inline-block">
                <img src="images/electronics.png" loading="lazy" width="51" alt="" class="image-2">
                <div class="text-block-6">Electronics</div>
              </a>
              <a id="w-node-_8bf29d7c-bcf0-ee93-17ec-1d4fb3417d7b-f21314e9" href="#" class="link-block-8 w-inline-block"><img src="images/cloth.png" loading="lazy" width="51" alt="" class="image-2">
                <div class="text-block-6">Clothing</div>
              </a>
              <a id="w-node-a80a8f69-e3bb-1a62-1b7e-5b3dcfd1342a-f21314e9" href="#" class="link-block-8 w-inline-block"><img src="images/food.png" loading="lazy" width="51" alt="" class="image-2">
                <div class="text-block-6">Food &amp; Beverage</div>
              </a>
              <a id="w-node-_7b6279ff-64cb-03e1-9677-9c013a8ed69c-f21314e9" href="#" class="link-block-8 w-inline-block"><img src="images/Gadgets.png" loading="lazy" width="51" alt="" class="image-2">
                <div class="text-block-6">Gadgets</div>
              </a>
              <a id="w-node-d9e392db-3a7e-a400-b503-1fab866f06ab-f21314e9" href="#" class="link-block-8 w-inline-block"><img src="images/furniture.png" loading="lazy" width="51" alt="" class="image-2">
                <div class="text-block-6">Furniture</div>
              </a>
              <a id="w-node-_79344ff0-0321-9bac-e19d-54f68835901b-f21314e9" href="#" class="link-block-8 w-inline-block"><img src="images/books.png" loading="lazy" width="51" alt="" class="image-2">
                <div class="text-block-6">Books</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MerchantCategoryCard",
};
</script>

<style scoped>

</style>
