<template>
  <div class="container merchant-group">
    <div class="div-block-36">
      <div class="text-block-29">Similar merchants</div>
    </div>
    <div class="w-layout-grid grid-4-copy">
      <div class="single-merchant-info" v-for="merchant in merchants" :key="merchant.merchantId">
        <div class="single-merchant">
          <div class="merchant-intro w-clearfix">
            <img
              :src="merchant.merchantPicture"
              loading="lazy"
              width="59"
              alt=""
              style="border-radius: 50%"
              class="merchant-logo male"
            />
            <a href="#" class="follow-button w-button">Follow</a>
          </div>
          <div class="merchant-info">
            <div class="merchant-company">{{ merchant.merchantName }}</div>
            <div class="merchant-description">
              Lorem ipsum dolor sit amhjdjsj. Neque turpis lorem
            </div>
          </div>
          <div class="merchant-figures">
            <div class="figure-container">
              <h4 class="followers-number">0</h4>
              <p class="followers-text">Followers</p>
            </div>
            <div class="figure-container">
              <h4 class="followers-number">0</h4>
              <p class="followers-text">Following</p>
            </div>
            <div class="figure-container">
              <h4 class="followers-number">0</h4>
              <p class="followers-text">Products</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SimilarMerchantCard",
  computed: {
    ...mapGetters({
      merchants: "merchant/getSimilarMerchants",
    }),
  },
};
</script>

<style scoped></style>
