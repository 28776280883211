import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import PostByCategory from "@/views/post/PostByCategory";
import MerchantProfile from "@/views/merchant/MerchantProfile";
import ShopFeeds from "@/views/post/ShopFeeds";
import AllMerchants from "@/views/merchant/AllMerchants";
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import ProductProfile from "@/views/product/ProductProfile";
import PostSearch from "@/views/post/PostSearch";
import Register from "@/views/auth/Register";
import Login from "@/views/auth/Login";
import ForgotPassword from "@/views/auth/ForgotPassword";
import Cart from "@/views/product/Cart";
import MyAccount from "@/views/profile/MyAccount";

Vue.use(VueRouter);

const routes = [
  {
    path: "/products/:productId",
    name: "product-profile",
    component: ProductProfile,
    beforeEnter: (routeTo, routeFrom, next) => {
      StoreUtils.commit(
        "product/SET_CURRENT_PRODUCT_ID",
        routeTo.params.productId
      );
      next();
    },
  },
  {
    path: "/merchants",
    name: "merchant-all",
    component: AllMerchants,
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
  },
   {
    path: "/profile",
    name: "profile",
    component: MyAccount,
  },
{
    path: "/post/feeds",
    name: "post-shop-feeds",
    component: ShopFeeds,
  },
  {
    path: "/merchant/profile/:merchantId",
    name: "merchant-profile",
    component: MerchantProfile,
    beforeEnter: (routeTo, routeFrom, next) => {
      StoreUtils.commit(
        "merchant/SET_CURRENT_MERCHANT_ID",
        routeTo.params.merchantId
      );
      next();
    },
  },
  {
    path: "/post/search/:keyword",
    name: "post-search",
    component: PostSearch,
    beforeEnter: (routeTo, routeFrom, next) => {
      StoreUtils.commit(
        "post/SET_CURRENT_SEARCH_KEYWORD",
        routeTo.params.keyword
      );
      next();
    },
  },
  {
    path: "/post/category/:categoryId",
    name: "post-by-category",
    component: PostByCategory,
    beforeEnter: (routeTo, routeFrom, next) => {
      StoreUtils.commit(
        "post/SET_CURRENT_POST_CATEGORY_ID",
        routeTo.params.categoryId
      );
      next();
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
