<template>
  <div class="app-table">
    <transition v-if="tableLoading" name="slide-fade" mode="out-in">
      <ComponentLoader />
    </transition>
    <transition-group v-else name="slide-fade" mode="out-in">
      <div v-for="post in allPosts" :key="post.postId" class="card is-post">
        <!-- Main wrap -->
        <transition
          v-if="post.postId !== openedCommentPostId"
          name="slide-fade"
          mode="out-in"
        >
          <post-card :current-post="post"></post-card>
        </transition>
        <!-- /Main wrap -->

        <!-- Post #1 Comments -->
        <transition v-else name="slide-fade" mode="out-in">
          <post-comment-card :current-post="post"></post-comment-card>
        </transition>
        <!-- /Post #1 Comments -->
      </div>
    </transition-group>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import PostCard from "@/components/cards/post/PostCard";
import PostCommentCard from "@/components/cards/post/PostCommentCard";
import ComponentLoader from "@/components/app/utility/loader/ComponentLoader";

export default {
  name: "FeedsTable",
  components: { ComponentLoader, PostCommentCard, PostCard },
  computed: {
    tableLoading() {
      return StoreUtils.rootGetters("loader/getTableLoader");
    },
    allPosts() {
      return StoreUtils.rootGetters("post/getAllPosts");
    },
    openedCommentPostId() {
      return StoreUtils.rootGetters("post/getOpenedCommentPostId");
    },
  },
  created() {
    this.closePostComments();
  },
  methods: {
    tableAction(data, action) {
      switch (action) {
        case "delete":
          // console.log("Approve Request");
          StoreUtils.dispatch("product/deleteProduct", {
            productId: data.id,
          });
          break;

        case "reject":
          // console.log("Reject Request");
          break;

        default:
          return false;
      }
    },
    closePostComments() {
      StoreUtils.commit("post/SET_OPENED_COMMENT_POST_ID", "");
    },
  },
};
</script>

<style scoped>
.app-table {
  margin: 0 0;
  padding: 0 20px 0 0;
}
.slide-fade-enter {
  opacity: 0;
  transform: translateY(10px);
}
.slide-fade-enter-to {
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave {
}
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
</style>
