import { render, staticRenderFns } from "./CategoriesSidebarCard.vue?vue&type=template&id=1b957b22&scoped=true&"
import script from "./CategoriesSidebarCard.vue?vue&type=script&lang=js&"
export * from "./CategoriesSidebarCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b957b22",
  null
  
)

export default component.exports