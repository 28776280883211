<template>
  <div @click="goToProductPage(product)" class="product-card hvr-grow">
    <!--    <p>{{product}}</p>-->
    <p v-if="product.pictures.length > 1">
      <img :src="`${product.pictures[0].url}`" alt="" class="product-image" />
    </p>
    <p v-else>
      <img src="https://via.placeholder.com/150" alt="" class="product-image" />
    </p>
    <p class="product-card-nuggets">{{ product.productDescription }}</p>
    <div class="product-card-info">
      <img
        class="product-card-info-img"
        :src="product.postOwner.merchantProfilePicture"
        alt=""
      />
      <div>
        <p class="product-card-info-name text-ellipse">
          <span>{{ product.postOwner.merchantName }}</span>
          <span class="verified"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M7 0.4C7.1 0.2 7.3 0.1 7.5 0.1 7.7 0 7.9 0 8.1 0 8.3 0.1 8.5 0.2 8.6 0.4 8.7 0.6 8.8 0.8 8.8 1 8.8 1.2 8.8 1.3 8.9 1.5 8.9 1.6 9 1.7 9.2 1.8 9.3 1.9 9.4 2 9.6 2 9.7 2 9.9 2 10.1 1.9 10.3 1.9 10.5 1.9 10.7 1.9 10.9 2 11 2.1 11.2 2.3 11.3 2.5 11.4 2.7 11.4 2.9 11.4 3.1 11.3 3.3 11.2 3.5 11.1 3.6 11 3.7 11 3.9 10.9 4 11 4.2 11 4.3 11.1 4.5 11.1 4.6 11.3 4.7 11.4 4.9 11.5 4.9 11.7 5 11.9 5 12 5.2 12.2 5.3 12.3 5.5 12.4 5.7 12.4 5.9 12.4 6.1 12.3 6.3 12.2 6.5 12 6.7 11.9 6.8 11.7 6.8 11.5 6.9 11.4 7 11.3 7.1 11.1 7.2 11.1 7.3 11 7.5 11 7.6 10.9 7.8 11 7.9 11 8.1 11.1 8.3 11.2 8.4 11.3 8.5 11.4 8.7 11.4 9 11.4 9.2 11.3 9.4 11.2 9.5 11 9.7 10.9 9.8 10.7 9.9 10.5 10 10.3 10 10.1 9.9 9.9 9.8 9.7 9.8 9.6 9.8 9.4 9.9 9.3 9.9 9.2 10 9 10.1 8.9 10.2 8.9 10.4 8.8 10.5 8.8 10.7 8.8 10.8 8.8 11 8.7 11.2 8.6 11.4 8.5 11.6 8.3 11.7 8.1 11.8 7.9 11.9 7.7 11.8 7.5 11.8 7.3 11.7 7.1 11.6 7 11.4 6.9 11.3 6.8 11.2 6.6 11.1 6.5 11 6.3 11 6.2 11 6 11 5.9 11 5.7 11.1 5.6 11.2 5.5 11.3 5.4 11.4 5.3 11.6 5.1 11.7 4.9 11.8 4.7 11.8 4.5 11.9 4.3 11.8 4.1 11.7 3.9 11.6 3.8 11.4 3.7 11.2 3.6 11 3.6 10.8 3.6 10.7 3.6 10.5 3.5 10.4 3.4 10.2 3.3 10.1 3.2 10 3.1 9.9 2.9 9.9 2.8 9.8 2.6 9.8 2.4 9.8 2.3 9.9 2.1 10 1.9 10 1.7 9.9 1.5 9.8 1.3 9.7 1.2 9.5 1.1 9.4 1 9.2 1 9 1 8.7 1.1 8.5 1.2 8.4 1.3 8.3 1.4 8.1 1.4 7.9 1.4 7.8 1.4 7.6 1.4 7.5 1.3 7.3 1.2 7.2 1.1 7.1 1 7 0.8 6.9 0.7 6.8 0.5 6.8 0.3 6.7 0.2 6.5 0.1 6.3 0 6.1 0 5.9 0 5.7 0.1 5.5 0.2 5.3 0.3 5.2 0.5 5 0.7 5 0.8 4.9 1 4.9 1.1 4.7 1.2 4.6 1.3 4.5 1.4 4.3 1.4 4.2 1.4 4 1.4 3.9 1.4 3.7 1.3 3.6 1.2 3.5 1.1 3.3 1 3.1 1 2.9 1 2.7 1.1 2.5 1.2 2.3 1.3 2.1 1.5 2 1.7 1.9 1.9 1.9 2.1 1.9 2.3 1.9 2.4 2 2.6 2 2.8 2 2.9 2 3.1 1.9 3.2 1.8 3.3 1.7 3.4 1.6 3.5 1.5 3.6 1.3 3.6 1.2 3.6 1 3.6 0.8 3.7 0.6 3.8 0.4 3.9 0.2 4.1 0.1 4.3 0 4.5 0 4.7 0 4.9 0.1 5.1 0.1 5.3 0.2 5.4 0.4 5.5 0.6 5.6 0.7 5.7 0.7 5.9 0.8 6 0.8 6.2 0.8 6.3 0.8 6.5 0.8 6.6 0.7 6.8 0.7 6.9 0.6 7 0.4Z"
                fill="#0F4FAA"
              />
              <path
                d="M9.3 4.4L8.8 3.9C8.8 3.9 8.7 3.9 8.7 3.9 8.7 3.9 8.7 3.9 8.7 3.9 8.6 3.9 8.6 3.9 8.6 3.9L5.2 7 4 5.9C4 5.8 4 5.8 3.9 5.8 3.9 5.8 3.9 5.8 3.9 5.9L3.4 6.3C3.4 6.3 3.3 6.3 3.3 6.3 3.3 6.3 3.3 6.4 3.3 6.4 3.3 6.4 3.3 6.4 3.3 6.4 3.3 6.4 3.4 6.4 3.4 6.4L4.9 7.8C5 7.9 5.1 7.9 5.2 8 5.4 7.9 5.5 7.9 5.6 7.8L9.3 4.5C9.3 4.5 9.3 4.5 9.3 4.5 9.3 4.4 9.3 4.4 9.3 4.4Z"
                fill="white"
              />
            </svg>
          </span>
        </p>
        <p class="product-card-info-cat">{{ product.productQuantity }}</p>
      </div>
      <p
        v-if="product.productCurrency === 'NGN'"
        class="product-card-info-price"
      >
        ₦{{ `${product.productPrice}` }}
      </p>
      <p
        v-if="product.productCurrency === 'GHN'"
        class="product-card-info-price"
      >
        ₵{{ `${product.productPrice}` }}
      </p>
    </div>
  </div>
</template>

<script>
import StoreUtils from "../../../utils/baseUtils/StoreUtils";
import RouterUtils from "@/utils/baseUtils/RouterUtils";

export default {
  name: "ProductAltCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    merchantProducts() {
      return StoreUtils.rootGetters("table/getTableData");
    },
  },
  methods: {
    goToProductPage(product) {
      StoreUtils.commit("product/SET_CURRENT_PRODUCT_DETAILS", product);
      RouterUtils.changeRouteUrlTo(`/products/${product.productId}`);
    },
  },
};
</script>

<style scoped></style>
