<template>
  <div>
    <AuthLayout :caption="`We will send you a link to reset your password`">
      <ForgotPasswordFormCard />
    </AuthLayout>
  </div>
</template>

<script>
import ForgotPasswordFormCard from "@/components/cards/auth/ForgotPasswordFormCard";
import AuthLayout from "@/components/app/layout/AuthLayout";
export default {
  name: "ForgotPassword",
  components: { AuthLayout, ForgotPasswordFormCard },
};
</script>

<style scoped></style>
