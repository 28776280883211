<template>
  <MerchantLayout>
    <div class="shop-feed-wrapper wf-section">
      <div class="container feeds">
        <div class="feeds-wrapper">
          <BecomeMerchantCard />
          <NewPostCard />
          <SuggestionsCard />
        </div>
      </div>
    </div>
  </MerchantLayout>
</template>

<script>
import FeedsTable from "@/components/tables/post/FeedsTable";
import SuggestionTable from "@/components/tables/post/SuggestionTable";
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import MerchantLayout from "@/components/app/layout/MerchantLayout";
import BecomeMerchantCard from "@/components/cards/merchant/BecomeMerchantCard";
import SuggestionsCard from "@/components/cards/merchant/SuggestionsCard";
import NewPostCard from "@/components/cards/post/NewPostCard";
export default {
  name: "ShopFeeds",
  components: {
    NewPostCard,
    SuggestionsCard,
    BecomeMerchantCard,
    MerchantLayout,
  },
  methods: {
    fetchShopFeeds() {
      StoreUtils.dispatch("post/fetchAllPosts");
    },
    fetchFollowSuggestions() {
      StoreUtils.dispatch("post/fetchFollowSuggestions");
    },
  },
  created() {
    this.fetchShopFeeds();
    this.fetchFollowSuggestions();
  },
};
</script>

<style scoped></style>
