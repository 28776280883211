<template>
  <div class="w-form">
    <FormulateForm v-model="formBody" @submit="register">
      <FormulateInput
        name="firstName"
        validation="required"
        placeholder="Firstname"
        type="text"
      />
      <FormulateInput
        name="lastName"
        validation="required"
        placeholder="Lastname"
        type="text"
      />
      <FormulateInput
        name="email"
        validation="required|email"
        placeholder="Email"
        type="email"
      />
      <FormulateInput
        name="mobileNumber"
        validation="required"
        placeholder="Phone Number"
        type="text"
      />
      <FormulateInput
        type="password"
        name="password"
        validation="required"
        placeholder="Password"
      />
      <FormulateInput type="submit" label="Continue" />
    </FormulateForm>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../utils/baseUtils/StoreUtils";
import RouterUtils from "../../../utils/baseUtils/RouterUtils";

export default {
  name: "RegisterForm",
  data() {
    return {
      formBody: {},
    };
  },
  computed: {
    ...mapState(["auth"]),
  },
  methods: {
    register(data) {
      const mobileNumber = data.mobileNumber;
      StoreUtils.commit("form/BUILD_FORM_BODY", data);
      StoreUtils.dispatch("auth/register");
    },
    toLogin() {
      RouterUtils.changeRouteTo(RouterUtils.routes.auth.LOGIN);
    },
  },
  created() {
    StoreUtils.commit("form/SET_FORM_STAGE_TO", 0);
  },
};
</script>

<style scoped></style>
