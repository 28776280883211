<template>
  <div class="app-footer">
    <p class="text-center">&copy; 2021 All right reserved</p>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped></style>
