import store from "../../store/store";

class StoreUtils {
  static actions = {
    loader: {
      showBlockingLoader: "loader/showBlockingLoader",
      showNonBlockingLoader: "loader/showNonBlockingLoader",
      showTableLoader: "loader/showTableLoader",
      showComponentLoader: "loader/showComponentLoader",
    },
    notification: {
      addNotificationSlide: "notification/addNotificationSlide",
      removeNotificationSlide: "notification/removeNotificationSlide",
      showNotificationModal: "notification/showNotificationModal",
      closeNotificationModal: "notification/closeNotificationModal",
    },
    router: {
      setActiveRoute: "router/setActiveRoute",
    },
    auth: {
      LOGIN: "auth/login",
      REGISTER: "auth/createProfile",
      FORGOT_PASSWORD: "auth/forgotPassword",
      INIT_APP: "auth/initApp",
      LOGOUT: "auth/logOut",
    },
    user: {
      SET_USER_INFO: "user/setUserInfo",
    },
    company: {
      SET_NEW_COMPANY: "company/setNewCompany",
    },
  };

  static mutations = {
    form: {
      RESET_FORM: "form/RESET_FORM",
      BUILD_FORM_BODY: "form/BUILD_FORM_BODY",
      INCREASE_FORM_STAGE_BY_ONE: "form/INCREASE_FORM_STAGE_BY_ONE",
    },
    table: {
      SET_TABLE_DATA: "table/SET_TABLE_DATA",
      SET_ALT_TABLE_DATA: "table/SET_ALT_TABLE_DATA",
      SET_TABLE_DATA_OBJECT: "table/SET_TABLE_DATA_OBJECT",
    },
  };

  static getters = {
    form: {
      GET_FORM_BODY: "form/getFormBody",
    },
    table: {
      GET_TABLE_DATA: "table/getTableData",
      GET_ALT_TABLE_DATA: "table/getAltTableData",
      GET_TABLE_DATA_OBJECT: "table/getTableDataObject",
    },
    router: {
      GET_ACTIVE_ROUTE: "router/getActiveRoute",
    },
    utils: {
      GET_CSV_JSON: "utils/getCsvJson",
    },
    auth: {},
    user: {
      GET_USER_EMAIL: "user/getUserEmail",
      GET_USER_ID: "user/getUserID",
      GET_CUSTOMER_ID: "user/getCustomerId",
    },
    customer: {
      GET_CUSTOMER_OBJECT: "customer/GET_CUSTOMER_OBJECT",
    },
    orders: {
      GET_SINGLE_CUSTOMER_ORDER: "orders/getSingleCustomerOrder",
    },
  };

  static dispatch(actionToDispatch, payload) {
    return store.dispatch(actionToDispatch, payload, { root: true });
  }

  static commit(mutationToCommit, payload) {
    return store.commit(mutationToCommit, payload, { root: true });
  }

  static rootGetters(getterToGet, payload) {
    if (payload) {
      return store.getters[getterToGet](payload);
    } else {
      return store.getters[getterToGet];
    }
  }
}

export default StoreUtils;
