<template>
  <div style="display: none" class="comment-modal">
    <div class="comment-modal-wrapper">
      <div class="image-holder">
        <div
          data-delay="4000"
          data-animation="slide"
          class="slider-2 w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="true"
        >
          <div class="mask-2 w-slider-mask">
            <div class="w-slide">
              <img
                src="~@/assets/images/img12-min.jpg"
                loading="lazy"
                sizes="100vw"
                srcset="
                  ~@/assets/images/img12-min-p-500.jpeg 500w,
                  ~@/assets/images/img12-min-p-800.jpeg 800w,
                  ~@/assets/images/img12-min.jpg        858w
                "
                alt=""
                class="image-21"
              />
            </div>
            <div class="w-slide">
              <img
                src="~@/assets/images/img12-min.jpg"
                loading="lazy"
                sizes="100vw"
                srcset="
                  ~@/assets/images/img12-min-p-500.jpeg 500w,
                  ~@/assets/images/img12-min-p-800.jpeg 800w,
                  ~@/assets/images/img12-min.jpg        858w
                "
                alt=""
                class="image-21"
              />
            </div>
          </div>
          <div class="left-arrow-2 w-slider-arrow-left"></div>
          <div class="right-arrow-2 w-slider-arrow-right"></div>
          <div class="slide-nav-2 w-slider-nav w-round"></div>
        </div>
      </div>
      <div class="comment-section">
        <div class="product-merchant-block">
          <div class="div-block-24">
            <img
              src="~@/assets/images/Male.svg"
              loading="lazy"
              width="40"
              alt=""
            />
          </div>
          <div>
            <div class="merchant">Salfun Global</div>
            <div class="user-comment">
              Lenovo Ideapad 4GBRAM/500 HDD Intel Celeron Dual Core Windows 10
              +free Mouse.
            </div>
            <div class="price">₦469,000</div>
            <div class="text-block-22">
              <a href="#" class="link-6">View product</a>
            </div>
          </div>
        </div>
        <div class="comment-box">
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
          <div class="div-block-25">
            <div class="div-block-26">
              <img
                src="~@/assets/images/Male.png"
                loading="lazy"
                width="31"
                alt=""
              />
            </div>
            <div>
              <div class="user-1">Adeniran Samuel</div>
              <div class="user-comment">
                I really like this product, can i get one delivered?
              </div>
              <div class="user-comment-copy">1 day</div>
            </div>
          </div>
        </div>
        <div class="post-details">
          <div class="like-comment">
            <div>
              <img
                src="~@/assets/images/heart.svg"
                loading="lazy"
                alt=""
                class="image-22"
              /><img src="~@/assets/images/comment.svg" loading="lazy" alt="" />
            </div>
          </div>
          <div class="text-block-23">23 likes</div>
          <div class="text-block-24">DECEMBER 19</div>
        </div>
        <div class="comment-textfield-box">
          <div class="form-block w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              class="form"
            >
              <a href="#" class="link-block-12 w-inline-block"></a
              ><input
                type="text"
                class="text-field-2 w-input"
                maxlength="256"
                name="comment"
                data-name="comment"
                placeholder="Add a comment..."
                id="comment"
                required=""
              /><input
                type="submit"
                value="Post"
                data-wait="Please wait..."
                class="submit-button-2 w-button"
              />
            </form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <a
          data-w-id="12c285ec-0b24-5eb9-3b10-f4f417e58894"
          href="#"
          class="cancel-button w-inline-block"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostModal",
  data() {
    return {
      isOpen: false,
      post: {},
    };
  },
  methods: {
    openImageModel: function () {

      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped></style>
