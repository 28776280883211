<template>
  <div class="container merchant-group">
    <div class="w-layout-grid grid-4">
      <div v-for="merchant in this.merchants" :key="merchant.id" class="single-merchant-info">
        <div class="single-merchant">
          <div class="merchant-intro w-clearfix">
            <img
              src="~@/assets/images/Male.svg"
              loading="lazy"
              width="59"
              alt=""
              class="merchant-logo male"
            />
            <a
              href="#"
              @mouseover="hover = true"
              @mouseleave="hover = false"
              :class="{ active: 'following' }"
              class="follow-button w-button"
              >Follow</a
            >
          </div>
          <div class="merchant-info">
            <a class="merchant-company" @click="openMerchant(merchant.merchantId)">{{ merchant.merchantName }}</a>
            <div class="merchant-description">
              {{ merchant.merchantName }}
            </div>
          </div>
          <div class="merchant-figures">
            <div class="figure-container">
              <h4 class="followers-number">0</h4>
              <p class="followers-text">Followers</p>
            </div>
            <div class="figure-container">
              <h4 class="followers-number">0</h4>
              <p class="followers-text">Following</p>
            </div>
            <div class="figure-container">
              <h4 class="followers-number">0</h4>
              <p class="followers-text">Products</p>
            </div>
          </div>
        </div>
        <div class="single-merchant-products"></div>
      </div>
      <div class="next-button-container w-clearfix">
        <a href="#" class="next-button w-button">Next</a>
      </div>
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import { mapGetters } from "vuex";
import router from "@/router/router";

export default {
  name: "AllMerchantCards",
  data(){
    return {
      active: false
    }
  },
  computed: {
    ...mapGetters({
      merchants: "merchant/getAllMerchants",
    }),
  },
  methods:{
    openMerchant(merchantId) {
      router.push({ name: "merchant-profile", params: { merchantId:  merchantId } })
    }
  },
  created() {
    StoreUtils.dispatch("merchant/fetchAllMerchants");
  },
};
</script>

<style scoped></style>
