<template>
  <div class="post-image">
    <carousel
      class="post-carousel"
      :items="1"
      :autoplay="false"
      :nav="true"
      :navText="navText"
    >
      <img
        v-for="(src, index) in pictures.map((picture) => picture.imageUrl)"
        :key="index"
        :src="src"
        class="post-img"
        alt=""
      />
    </carousel>
    <!--        </a>-->
    <!-- Action buttons -->
  </div>
</template>

<script>
import carousel from "vue-owl-carousel2";

export default {
  name: "PostImageCard",
  props: {
    pictures: {
      type: Array,
      required: true,
    },
  },
  components: {
    carousel,
  },

  data() {
    return {
      navText: [
        '<span class="nav-control prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0zM313.7 347.6c8.3 8.3 8.3 21.8 0 30.2 -4.2 4.2-9.6 6.3-15.1 6.3 -5.5 0-10.9-2.1-15.1-6.3L176.9 271.1c-8.3-8.3-8.3-21.8 0-30.2l106.7-106.7c8.3-8.3 21.8-8.3 30.2 0s8.3 21.8 0 30.2L222.2 256 313.7 347.6z" /></svg></span>',
        '<span class="nav-control next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  d="M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0zM335.1 271.1L228.4 377.7c-4.2 4.2-9.6 6.3-15.1 6.3 -5.5 0-10.9-2.1-15.1-6.3 -8.3-8.3-8.3-21.8 0-30.2L289.8 256l-91.6-91.6c-8.3-8.3-8.3-21.8 0-30.2s21.8-8.3 30.2 0l106.7 106.7C343.4 249.3 343.4 262.7 335.1 271.1z" /></svg></span>',
      ],
      sliderValue: 2,
    };
  },
};
</script>

<style scoped></style>
