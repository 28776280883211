export const namespaced = true;

export const state = {
  defaultModal: false,
  altModal: false,
  introModal: true,
  paymentModal: false,
};
export const getters = {
  getDefaultModal: (state) => {
    return state.defaultModal;
  },
  getAltModal: (state) => {
    return state.altModal;
  },
  getIntroModal: (state) => {
    return state.introModal;
  },
  getPaymentModal: (state) => {
    return state.paymentModal;
  },
};

export const mutations = {
  SHOW_DEFAULT_MODAL(state, status) {
    state.defaultModal = status;
  },
  SHOW_ALT_MODAL(state, status) {
    state.altModal = status;
  },
  SHOW_INTRO_MODAL(state, status) {
    state.introModal = status;
  },
  SHOW_PAYMENT_MODAL(state, status) {
    state.paymentModal = status;
  },
};
export const actions = {};
