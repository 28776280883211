<template>
  <AuthLayout :caption="'Enter your credentials to sign in'">
    <LoginForm />
  </AuthLayout>
</template>

<script>
import LoginForm from "@/components/forms/auth/LoginForm";
import AuthLayout from "@/components/app/layout/AuthLayout";
export default {
  name: "Register",
  components: { AuthLayout, LoginForm },
};
</script>

<style scoped></style>
