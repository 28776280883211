<template>
  <div class="did-you-forget-something">
    <div class="header-copy">
      <div class="text-block-29">Did you forget something?</div>
    </div>
    <div class="products">
      <div class="w-layout-grid grid-5">
        <div
          class="product-card-wrapper"
          v-for="product in products.slice(0, 4)"
          :key="product.id"
        >
          <div class="div-block-34">
            <img
              :src="product.pictures[0] && product.pictures[0].url"
              loading="lazy"
              style="max-height: 190px; border-radius: 5px"
              alt=""
            />
          </div>
          <div class="div-block-35">
            <div class="text-block-30">
              {{ product.productName }}
            </div>
            <div class="text-block-31">NGN {{ product.productPrice }}</div>
          </div>
          <div class="div-block-35">
            <a href="#" class="button-3 w-button">View product</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StoreUtils from "@/utils/baseUtils/StoreUtils";

export default {
  name: "SuggestedProductCard",
  computed: {
    ...mapGetters({
      products: "product/getTopProducts",
    }),
  },
  methods: {
  },
  created() {
    StoreUtils.dispatch("product/fetchTopProducts");
  },
};
</script>

<style scoped></style>
