<template>
  <AuthLayout :caption="'Create Account'">
    <RegisterFormCard />
  </AuthLayout>
</template>

<script>
import RegisterFormCard from "@/components/cards/auth/RegisterFormCard";
import AuthLayout from "@/components/app/layout/AuthLayout";
export default {
  name: "Register",
  components: { AuthLayout, RegisterFormCard },
};
</script>

<style scoped></style>
