export const namespaced = true;

export const state = {
  blockingLoader: true,
  nonBlockingLoader: false,
  tableLoader: true,
  componentLoader: false,
  actionButtonLoader: false,
  tableActionButtonLoader: false,
  loaders: {
    BLOCKING: "loader/showBlockingLoader",
    NONBLOCKING: "loader/showNonBlockingLoader",
    TABLE: "loader/showTableLoader",
    COMPONENT: '"loader/showComponentLoader"',
    TABLE_ACTION_BUTTON_LOADER: '"loader/showTableActionButtonLoader"',
    ACTION_BUTTON_LOADER: '"loader/showActionButtonLoader"',
  },
};
export const getters = {
  getBlockingLoader: (state) => {
    return state.blockingLoader;
  },
  getNonBlockingLoader: (state) => {
    return state.nonBlockingLoader;
  },
  getTableLoader: (state) => {
    return state.tableLoader;
  },
  getComponentLoader: (state) => {
    return state.componentLoader;
  },
  getTableActionButtonLoader: (state) => {
    return state.tableActionButtonLoader;
  },
  getActionButtonLoader: (state) => {
    return state.actionButtonLoader;
  },
  getLoader: (state) => (loaderString) => {
    switch (loaderString) {
      case state.loaders.BLOCKING:
        return state.blockingLoader;
      case state.loaders.NONBLOCKING:
        return state.nonBlockingLoader;
      case state.loaders.TABLE:
        return state.tableLoader;
      case state.loaders.COMPONENT:
        return state.componentLoader;
      case state.loaders.TABLE_ACTION_BUTTON_LOADER:
        return state.tableActionButtonLoader;
      case state.loaders.ACTION_BUTTON_LOADER:
        return state.actionButtonLoader;
      default:
        return state.loaders.BLOCKING;
    }
  },
};

export const mutations = {
  SET_ACTION_BUTTON_LOADER(state, status) {
    state.actionButtonLoader = status;
  },
  SET_TABLE_ACTION_BUTTON_LOADER(state, status) {
    state.tableActionButtonLoader = status;
  },
  SET_BLOCKING_LOADER(state, status) {
    state.blockingLoader = status;
  },
  SET_NON_BLOCKING_LOADER(state, status) {
    state.nonBlockingLoader = status;
  },
  SET_TABLE_LOADER(state, status) {
    state.tableLoader = status;
  },
  SET_COMPONENT_LOADER(state, status) {
    state.componentLoader = status;
  },
};
export const actions = {
  showActionButtonLoader({ commit }, status) {
    commit("SET_ACTION_BUTTON_LOADER", status);
  },
  showTableActionButtonLoader({ commit }, status) {
    commit("SET_TABLE_ACTION_BUTTON_LOADER", status);
  },
  showBlockingLoader({ commit }, status) {
    commit("SET_BLOCKING_LOADER", status);
  },
  showNonBlockingLoader({ commit }, status) {
    commit("SET_NON_BLOCKING_LOADER", status);
  },
  showTableLoader({ commit }, status) {
    commit("SET_TABLE_LOADER", status);
  },
  showComponentLoader({ commit }, status) {
    commit("SET_COMPONENT_LOADER", status);
  },
};
