<template>
  <div class="become-a-merchant-wrapper">
    <h1 class="heading-12">Become a <br>Merchant Today</h1>
    <div class="text-block-10">Amet minim mollit non deserunt ullamco est </div>
    <a href="#" class="button-2 w-button">Join now</a>
  </div>
</template>

<script>
export default {
  name: "BecomeMerchantCard"
};
</script>

<style scoped>

</style>
