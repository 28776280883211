import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import "swiper/css/swiper.css";
import VueCarousel from "vue-carousel";


Vue.config.productionTip = false;

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(VueCarousel);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Vodal from "vodal";

Vue.component(Vodal.name, Vodal);
import "vodal/common.css";
import "vodal/rotate.css";

import CheckboxRadio from "vue-checkbox-radio";

Vue.use(CheckboxRadio);
import "./filter.js";

import Clipboard from "v-clipboard";
Vue.use(Clipboard);


import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VueFormulate from "@braid/vue-formulate";

Vue.use(VueFormulate, {
  library: {
    autocomplete: {
      classification: "text",
      component: "MyFormulateAutocomplete",
    },
  },
});

import VueFriendlyIframe from "vue-friendly-iframe";
Vue.use(VueFriendlyIframe);

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
