<template>
  <div class="top-product wf-section">
    <div class="container merchants">
      <div class="top-product-wrapper">
        <div class="top-wrapper-header">
          <h1 class="heading-4">Top products</h1>
        </div>
        <div class="w-layout-grid grid-5">
          <div
            class="product-card-wrapper"
            v-for="product in this.products.slice(0, 4)"
            :key="product.id"
          >
            <div class="div-block-34">
              <img
                :src="product.pictures[0] && product.pictures[0].url"
                loading="lazy"
                alt=""
              />
            </div>
            <div class="div-block-35">
              <div class="text-block-30">
                Danami Plain Long Sleeve T-Shirt- Black
              </div>
              <div class="text-block-31">NGN 469,000</div>
              <div class="div-block-35">
                <a href="#" class="button-3 w-button">View product</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import { mapGetters } from "vuex";
export default {
  name: "TopProduct",
  computed: {
    ...mapGetters({
      products: "product/getTopProducts",
    }),
  },
  methods: {},
  created() {
    StoreUtils.dispatch("product/fetchTopProducts");
  },
};
</script>

<style scoped></style>
