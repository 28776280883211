export const namespaced = true;

export const state = {
  csvJson: [],
  nextId: 1,
};

export const getters = {
  getCsvJson: (state) => {
    return state.csvJson;
  },
};

export const mutations = {
  SET_CURRENT_CSV_JSON(state, payload) {
    state.csvJson = payload.map((entry) => {
      entry.id = state.nextId;
      state.nextId++;
      entry.action = "";
      return entry;
    });
  },
  REMOVE_ENTRY_FROM_CURRENT_CSV_JSON(state, payload) {
    state.csvJson = state.csvJson.filter((entry) => entry.id !== payload.id);
  },
};

export const actions = {};
