import MerchantService from "../../services/MerchantService";
import StoreUtils from "../../utils/baseUtils/StoreUtils";
import LoaderUtils from "../../utils/baseUtils/LoaderUtils";

const merchantService = new MerchantService();

export const namespaced = true;

const getDefaultState = () => {
  return {
    allMerchants: [],
    topMerchants: [],
    merchantProducts: [],
    similarMerchants: [],
    currentMerchant: {},
    currentMerchantId: "103",
  };
};
export const state = getDefaultState();

export const getters = {
  getTopMerchants: (state) => {
    return state.topMerchants;
  },
  getMerchantProducts: (state) => {
    return state.merchantProducts;
  },
  getSimilarMerchants: (state) => {
    return state.similarMerchants;
  },
  getCurrentMerchant: (state) => {
    return state.currentMerchant;
  },
  getCurrentMerchantId: (state) => {
    return state.currentMerchantId;
  },
  getAllMerchants: (state) => {
    return state.allMerchants;
  },
};

export const mutations = {
  SET_TOP_MERCHANTS(state, payload) {
    state.topMerchants = payload;
  },
  SET_MERCHANT_PRODUCTS(state, payload) {
    state.merchantProducts = payload;
  },
  SET_SIMILAR_MERCHANTS(state, payload) {
    state.similarMerchants = payload;
  },
  SET_CURRENT_MERCHANT(state, payload) {
    state.currentMerchant = { ...state.currentMerchant, ...payload };
  },
  SET_CURRENT_MERCHANT_ID(state, payload) {
    state.currentMerchantId = payload;
  },
  SET_ALL_MERCHANTS(state, payload) {
    state.allMerchants = payload;
  },
};

export const actions = {
  fetchAllMerchants() {
    let payload = {};
    let successAction = (responseData) => {
      StoreUtils.commit("merchant/SET_ALL_MERCHANTS", responseData.data);
    };

    return merchantService.fetchAllMerchants(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT
    );
  },
  fetchMerchantDetails(action, payload) {
    let successAction = (responseData) => {
      console.log(responseData);
      StoreUtils.commit("merchant/SET_CURRENT_MERCHANT", responseData.data);
    };
    return merchantService.fetchMerchantDetails(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT
    );
  },

  fetchTopMerchants() {
    let payload = {};
    let successAction = (responseData) => {
      StoreUtils.commit("merchant/SET_TOP_MERCHANTS", responseData.data);
    };

    return merchantService.fetchTopMerchants(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING,
      null,
      false
    );
  },
  fetchMerchantProducts(undefined, { merchantId }) {
    let payload = {
      merchantId,
    };
    let successAction = (responseData) => {
      console.log(responseData);
      StoreUtils.commit("merchant/SET_MERCHANT_PRODUCTS", responseData.data);
      StoreUtils.commit(
        "merchant/SET_CURRENT_MERCHANT",
        responseData.merchantDetails
      );
    };
    return merchantService.fetchMerchantProducts(
      payload,
      successAction,
      LoaderUtils.types.COMPONENT,
      null,
      false
    );
  },
  fetchSimilarMerchants(undefined, { merchantId }) {
    let payload = {
      merchantId,
    };
    let successAction = (responseData) => {
      console.log(responseData);
      StoreUtils.commit("merchant/SET_SIMILAR_MERCHANTS", responseData.data);
    };
    return merchantService.fetchSimilarMerchants(
      payload,
      successAction,
      LoaderUtils.types.NONE,
      null,
      false
    );
  },
};
