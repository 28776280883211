export const namespaced = true;

export const state = {
  tableData: [],
  altTableData: [],
  tableDataObject: {},
};

export const getters = {
  getTableData: (state) => {
    return state.tableData;
  },
  getFirstName: (state) => {
    return state.tableDataObject.firstName;
  },
  getLastName: (state) => {
    return state.tableDataObject.lastName;
  },
  getPhoneNumber: (state) => {
    return state.tableDataObject.phone;
  },
  getEmail: (state) => {
    return state.tableDataObject.email;
  },
  getAltTableData: (state) => {
    return state.altTableData;
  },
  getTableDataObject: (state) => {
    return state.tableDataObject;
  },
};

export const mutations = {
  SET_TABLE_DATA(state, payload) {
    state.tableData = payload;
  },
  SET_ALT_TABLE_DATA(state, payload) {
    state.altTableData = payload;
  },
  SET_TABLE_DATA_OBJECT(state, payload) {
    state.tableDataObject = payload;
  },
};

export const actions = {};
