<template>
  <div class="app-section">
    <h1 class="title">{{ currentPostCategoryName }}</h1>

    <transition v-if="tableLoading" name="slide-fade" mode="out-in">
      <ComponentLoader />
    </transition>
    <div v-else class="merchant-product-table">
      <ProductAltCard
        v-for="(product, index) in postsInCategory"
        :key="index"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import ProductAltCard from "@/components/cards/product/ProductAltCard";
import ComponentLoader from "@/components/app/utility/loader/ComponentLoader";

export default {
  name: "PostsByCategoryTable",
  components: { ComponentLoader, ProductAltCard },
  computed: {
    tableLoading() {
      return StoreUtils.rootGetters("loader/getTableLoader");
    },
    currentPostCategoryName() {
      return StoreUtils.rootGetters("post/getCurrentPostCategoryName");
    },
    postsInCategory() {
      return StoreUtils.rootGetters("table/getTableData");
    },
  },
};
</script>

<style scoped></style>
