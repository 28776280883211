<template>
  <div class="bottom-nav">
    <div class="w-layout-grid grid-3">
      <router-link
        id="w-node-_111df0a6-925a-3419-94a6-1e327e617bb1-7e617baf"
        class="home-bottom w-inline-block"
        :to="{ name: 'home' }"
      ></router-link>
      <router-link
        id="w-node-_111df0a6-925a-3419-94a6-1e327e617bb3-7e617baf"
        active-class="w--current"
        class="shopfeed-bottom w-inline-block"
        :to="{ name: 'post-shop-feeds' }"
      ></router-link>
      <router-link
        id="w-node-_111df0a6-925a-3419-94a6-1e327e617bb5-7e617baf"
        aria-current="page"
        active-class="w--current"
        class="merchant-bottom w-inline-block"
        :to="{ name: 'merchant-all' }"
      ></router-link>
      <router-link
        id="w-node-_111df0a6-925a-3419-94a6-1e327e617bb7-7e617baf"
        active-class="w--current"
        class="cart-bottom w-inline-block"
        :to="{ name: 'cart' }"
      ></router-link>
      <router-link
        id="w-node-_111df0a6-925a-3419-94a6-1e327e617bb9-7e617baf"
        active-class="w--current"
        class="account-bottom w-inline-block"
        to="home"
      >
      </router-link>
    </div>
  </div>
</template>

<script>
import RouterUtils from "@/utils/baseUtils/RouterUtils";

export default {
  name: "BottomNav",
  methods: {
    changeRoute(path = "home") {
      RouterUtils.changeRouteTo(path);
    },
  },
};
</script>

<style scoped></style>
