import AuthService from "../../services/AuthService";
const authService = new AuthService();
import RouterUtils from "../../utils/baseUtils/RouterUtils";
import StoreUtils from "../../utils/baseUtils/StoreUtils";
import LoaderUtils from "../../utils/baseUtils/LoaderUtils";

export const namespaced = true;

export const state = {
  token: "",
};

export const getters = {
  getAuthToken: (state) => {
    return state.token || localStorage.getItem("afropay.customer.auth.token");
  },
  getAuthBusinessId: (state) => {
    return state.businessId;
  },
};

export const mutations = {
  SET_AUTH_TOKEN(state, payload) {
    state.token = `${payload}`;
    saveToLocalStorage("afropay.customer.auth.token", payload);
  },
  RESET_AUTH_TOKEN(state) {
    state.token = ``;
  },
  SET_AUTH_BUSINESS_ID(state, payload) {
    state.businessId = payload;
  },
};

export const actions = {
  login() {
    let formBody = StoreUtils.rootGetters(
      StoreUtils.getters.form.GET_FORM_BODY
    );

    let payload = {
      username: formBody.username,
      countryCode: "234",
      password: formBody.password,
    };
    let successAction = responseData => {
      //Save user info to the store
      StoreUtils.commit("auth/SET_AUTH_TOKEN", responseData.token);
      StoreUtils.commit("user/SET_USER_INFO", responseData);

      //Route the User to the Dashboard
      RouterUtils.changeRouteTo(RouterUtils.routes.HOME);
    };

    return authService.login(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },

  validateAuthToken() {
    let payload = {
      token: StoreUtils.rootGetters("auth/getAuthToken"),
    };

    let successAction = responseData => {
      //Save user info to the store
      StoreUtils.commit("auth/SET_AUTH_TOKEN", responseData.token);
      StoreUtils.commit("user/SET_USER_INFO", responseData);
    };

    return authService.validateAuthToken(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },
  register() {
    let formBody = StoreUtils.rootGetters(
      StoreUtils.getters.form.GET_FORM_BODY
    );

    let payload = {
      firstName: formBody.firstName,
      lastName: formBody.lastName,
      email: formBody.email,
      password: formBody.password,
      mobileNumber: formBody.mobileNumber,
    };

    let successAction = () => {
      let mobileNumber = payload.mobileNumber;
      StoreUtils.dispatch("auth/sendOTP", { mobileNumber });
    };

    return authService.register(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },
  registerComplete() {
    let formBody = StoreUtils.rootGetters(
      StoreUtils.getters.form.GET_FORM_BODY
    );

    let payload = {
      request: "otp",
      otp: formBody.otp,
      mobileNumber: formBody.mobileNumber
    };

    let successAction = responseData => {
      //Save user info to the store
      StoreUtils.commit("user/SET_USER_INFO", responseData);
      StoreUtils.commit("form/RESET_FORM_BODY");
      RouterUtils.changeRouteTo(RouterUtils.routes.HOME);
    };

    return authService.registerComplete(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },

  forgotPassword() {
    let formBody = StoreUtils.rootGetters(
      StoreUtils.getters.form.GET_FORM_BODY
    );

    let payload = {
      password: formBody.password,
      mobileNumber: formBody.mobileNumber,
      otp: formBody.otp
    };

    let successAction = responseData => {
      //Save user info to the store
      // StoreUtils.commit("user/SET_USER_INFO", responseData);
      StoreUtils.commit("form/RESET_FORM_BODY");
      RouterUtils.changeRouteTo(RouterUtils.routes.auth.LOGIN);
    };

    return authService.forgotPassword(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },
  sendOTP() {
    let formBody = StoreUtils.rootGetters(
      StoreUtils.getters.form.GET_FORM_BODY
    );

    let payload = {
      request: "otp",
      mobileNumber: formBody.mobileNumber,
    };

    let successAction = responseData => {
      //Save user info to the store
      // StoreUtils.commit("user/SET_USER_INFO", responseData);
      StoreUtils.commit("form/INCREASE_FORM_STAGE_BY_ONE");
    };

    return authService.sendOTP(
      payload,
      successAction,
      LoaderUtils.types.BLOCKING
    );
  },

  logOut() {
    StoreUtils.commit("auth/RESET_AUTH_TOKEN");
    StoreUtils.commit("user/SET_USER_INFO", {});
    saveToLocalStorage("afropay.merchant.auth.token", "");
    localStorage.clear();
    RouterUtils.changeBaseRouteTo(RouterUtils.routes.auth.LOGIN);
  },
};

function saveToLocalStorage(key, payload) {
  window.localStorage.setItem(key, payload);
}
