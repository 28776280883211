<template>
  <MerchantLayout>
    <div class="merchant-section-copy wf-section">
      <div class="container merchant">
        <div class="div-block-28-copy">
          <div class="breadcrumb">
            <div class="text-block-32">
              <a href="#" class="breadcrumblink">Merchants</a>
            </div>
            <div class="text-block-32">
              <a href="#" class="breadcrumblink">Salfun Global</a>
            </div>
            <div class="text-block-32 link-copy">Knight&#x27;s.....</div>
          </div>
          <ProductDetailsCard />
        </div>
      </div>
      <ProductDescriptionCard />
      <div class="container merchant-group">
        <SuggestedProductCard />
      </div>
    </div>
  </MerchantLayout>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import MerchantLayout from "@/components/app/layout/MerchantLayout";
import SuggestedProductCard from "@/components/cards/product/SuggestedProductCard";
import ProductDescriptionCard from "@/components/cards/product/ProductDescriptionCard";
import ProductDetailsCard from "@/components/cards/product/ProductDetailsCard";
export default {
  name: "ProductProfile",
  components: {
    ProductDetailsCard,
    ProductDescriptionCard,
    SuggestedProductCard,
    MerchantLayout,
  },
  created() {
    const productId = this.$route.params.productId;
    StoreUtils.dispatch("product/fetchProductDetails", { productId });
    StoreUtils.dispatch("product/fetchRelatedProducts", { productId });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
