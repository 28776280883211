<template>
  <div class="suggestions">
    <div class="card">
      <div class="card-heading is-bordered">
        <h4>Suggestions</h4>
      </div>
      <div class="card-body no-padding">
        <component-loader v-if="componentLoading" />
        <transition-group v-else name="fade">
          <!-- Suggested friend -->
          <follow-suggestion-card
            v-for="suggestion in followSuggestions"
            :key="suggestion.merchantId"
            :data="suggestion"
          ></follow-suggestion-card>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import FollowSuggestionCard from "@/components/cards/post/FollowSuggestionCard";
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import ComponentLoader from "@/components/app/utility/loader/ComponentLoader";
export default {
  name: "SuggestionTable",
  components: { ComponentLoader, FollowSuggestionCard },
  computed: {
    componentLoading() {
      return StoreUtils.rootGetters("loader/getComponentLoader");
    },
    followSuggestions() {
      return StoreUtils.rootGetters("post/getFollowSuggestions");
    },
  },
};
</script>

<style scoped></style>
