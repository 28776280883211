<template>
  <div class="container merchant-profile-copy">
    <div class="div-block-32">
      <div class="header product-details">
        <div class="text-block-29">Product description</div>
      </div>
      <div>
        <ul role="list" class="list">
          <li>{{ product.productDescription }}</li>
        </ul>
      </div>
    </div>
    <div class="div-block-32">
      <div class="header product-details">
        <div class="text-block-29">Specifications</div>
      </div>
      <div>
        <ul role="list" class="list"></ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductDescriptionCard",
  computed: {
    ...mapGetters({
      product: "product/getCurrentProductDetails",
    }),
  },
};
</script>

<style scoped></style>
