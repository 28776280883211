<template>
  <div class="login-page-wrapper wf-section">
    <div class="sign-up-content">
      <div class="sign-up-form">
        <img
          src="~@/assets/images/afropay-logo.svg"
          loading="lazy"
          alt=""
          class="image"
        />
        <div class="text-block">{{ caption }}</div>
        <slot></slot>
      </div>
      <div class="returning-user">
        <div class="text-block-2">
          Not registered yet?
          <a @click="toRegister" class="link"
            ><strong class="bold-text">Create an Account</strong></a
          >
        </div>
      </div>
      <AuthFooter />
    </div>
  </div>
</template>

<script>
import AuthFooter from "@/components/cards/auth/AuthFooter";
import RouterUtils from "@/utils/baseUtils/RouterUtils";

export default {
  props: ['caption'],
  name: "AuthLayout",
  components: { AuthFooter },
  methods: {
    toRegister() {
      RouterUtils.changeRouteTo(RouterUtils.routes.auth.REGISTER);
    },
  },
};
</script>

<style lang="scss">

</style>
