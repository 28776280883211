<template>
  <div>
    <ForgotPasswordFormFirstStage v-if="formStage === 0" />
    <ForgotPasswordFormFinalStage v-if="formStage === 1" />
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import ForgotPasswordFormFinalStage from "@/components/forms/auth/ForgotPasswordFormFinalStage";
import ForgotPasswordFormFirstStage from "@/components/forms/auth/ForgotPasswordFormFirstStage";
export default {
  name: "ForgotPasswordFormCard",
  components: { ForgotPasswordFormFirstStage, ForgotPasswordFormFinalStage },
  computed: {
    formStage() {
      return StoreUtils.rootGetters("form/getFormStage");
    },
  },
};
</script>

<style scoped></style>
