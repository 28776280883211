<template>
  <MerchantLayout>
    <MerchantBanner />
    <MerchantCategoryCard />
    <MerchantBanner2 />
    <TopProduct />
  </MerchantLayout>
</template>

<script>


import MerchantLayout from "@/components/app/layout/MerchantLayout";
import MerchantBanner from "@/components/cards/merchant/MerchantBanner";
import MerchantCategoryCard from "@/components/cards/merchant/MerchantCategoryCard";
import MerchantBanner2 from "@/components/cards/merchant/MerchantBanner2";
import TopProduct from "@/components/cards/product/TopProduct";
export default {
  name: "Home",
  components: {
    TopProduct,
    MerchantBanner2,
    MerchantCategoryCard,
    MerchantBanner,
    MerchantLayout

  },
  created() {},
};
</script>

<style scoped></style>
