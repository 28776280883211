<template>
  <div class="footer-nav">
    <div class="div-block-3">
      <a href="#" class="link-block w-inline-block">
        <div class="text-block-4">Home</div>
      </a>
      <a href="#" class="link-block-2 w-inline-block">
        <div class="text-block-4">About</div>
      </a>
      <a href="#" class="link-block-3 w-inline-block">
        <div class="text-block-4">Help</div>
      </a>
      <a href="#" class="link-block-4 w-inline-block">
        <div class="text-block-4">Privacy</div>
      </a>
      <a href="#" class="link-block-5 w-inline-block">
        <div class="text-block-4">Terms</div>
      </a>
    </div>
    <div>
      <div class="text-block-4 center">©2021 Afropays, Inc. All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthFooter"
};
</script>

<style scoped lang="scss">

</style>
