<template>
  <div
    data-animation="default"
    data-collapse="none"
    data-duration="400"
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    class="navbar w-nav"
  >
    <div class="container">
      <a href="#" class="w-nav-brand"
        ><img
          src="~@/assets/images/afropay-logo.svg"
          loading="lazy"
          alt=""
          class="image-16" />
        <img
          src="~@/assets/images/small-logo.svg"
          loading="lazy"
          alt=""
          class="image-15"
      /></a>
      <form class="search w-form">
        <input
          type="search"
          class="search-input w-input"
          maxlength="256"
          name="query"
          placeholder="Search for Products or Merchants"
          id="search"
          required=""
        />
        <input type="submit" value="Search" class="search-button w-button" />
      </form>
      <nav role="navigation" class="nav-menu w-nav-menu">
        <router-link
          exact-active-class="w--current"
          :to="{ name: 'home' }"
          aria-current="page"
          class="home w-nav-link"
        ></router-link>
        <router-link
          active-class="w--current"
          :to="{ name: 'post-shop-feeds' }"
          class="shop-feeds w-nav-link"
        ></router-link>
        <router-link
          active-class="w--current"
          :to="{ name: 'merchant-all' }"
          class="merchants w-nav-link"
        >
        </router-link>
        <router-link
          :to="{ name: 'cart' }"
          active-class="w--current"
          class="carts w-nav-link"
        ></router-link>
        <div data-hover="false" data-delay="0" class="account-dropdown w-dropdown">
          <router-link :to="{ name: 'profile' }">
            <div class="dropdown-toggle w-dropdown-toggle">
          </div>
          </router-link>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import RouterUtils from "@/utils/baseUtils/RouterUtils";

export default {
  name: "TopNav",
  computed: {},
  methods: {
    changeRoute(path = "home") {
      RouterUtils.changeRouteTo(path);
    },
  },
};
</script>

<style scoped></style>
