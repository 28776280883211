<template>
  <div class="cart-content">
    <div class="cart-heading">
      <div class="text-block-36">
        My Shopping Cart ({{ cart.length }} items)
      </div>
    </div>
    <div class="cart-items" v-for="item in this.cart" :key="item.id">
      <div class="div-block-46">
        <div class="div-block-47">
          <img
            :src="
              item.pictures && item.pictures[0] && item.pictures[0].imageUrl
            "
            loading="lazy"
            alt=""
            class="image-24"
          />
        </div>
        <div class="div-block-49">
          <div class="text-block-41">
            <a href="#" class="link-11">{{ item.name }}</a>
          </div>
          <div class="text-block-40">₦ {{ item.price | moneyFormat }}</div>

          <div class="w-form">
            <div class="text-block-35">Quantity:</div>
            <div class="div-block-41">
              <button
                @click="change('minus', item)"
                class="minus-button w-button"
              ></button>
              <div>{{ item.quantity }}</div>
              <button
                @click="change('add', item)"
                class="add-button w-button"
              ></button>

            </div>
          </div>
          Options:

        </div>
        <div class="div-block-48">
          <a
            @click="removeProduct(item)"
            class="link-block-13 w-inline-block"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import NotificationUtils from "@/utils/baseUtils/NotificationUtils";

export default {
  name: "CartContentCard",
  data() {
    return {
      quantity: 1,
    };
  },
  computed: {
    ...mapGetters({
      cart: "product/getCartProducts",
      singleItem: "product/getSingleCart",
    })
  },
  methods: {
    change: function (direction, item) {
      const itemToIncrement = this.singleItem(item.id);
      if (itemToIncrement.quantity === 1 && direction === "minus") return;
      direction === "add" ? itemToIncrement.quantity++ : itemToIncrement.quantity--;
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    removeProduct(item) {
      StoreUtils.commit("product/REMOVE_CART", item);
      NotificationUtils.addNotificationSlide(
        "item removed",
        NotificationUtils.type.SUCCESS
      );
    },
  },
};
</script>

<style scoped></style>
