<template>
  <div class="total">
    <div class="text-block-36">Total</div>
    <div class="div-block-44"></div>
    <div class="div-block-45-copy">
      <div class="text-block-37">Sub-total</div>
      <div class="text-block-38">
        ₦{{ `${totalAmount}` | nairaAmountFilter }}
      </div>
    </div>
    <div class="div-block-45">
      <div class="text-block-37">Delivery</div>
      <div class="text-block-39">Calculated at checkout</div>
    </div>
    <div class="div-block-45">

    Options:
    <FormulateInput
      type="select" :options="['Loan', 'Card', 'Funds Transfer', 'Letter of Credit']">

    </FormulateInput></div>
    <a href="#" class="button-4 w-button">Checkout</a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CartTotalCard",
  computed: {
    ...mapGetters({
      cart: "product/getCartProducts",
      totalAmount: "product/getCartAmount",
    }),
  },
};
</script>

<style scoped></style>
