<template>
  <div class="login-form">
    <div class="form-panel">
      <FormulateForm v-model="formBody" @submit="submit">
        <FormulateInput
          name="password"
          validation="required"
          placeholder="Password"
          type="password"
        />
        <FormulateInput
          name="otp"
          validation="required"
          placeholder="OTP"
          type="text"
        />
        <FormulateInput type="submit" label="Submit" />
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../utils/baseUtils/StoreUtils";
import RouterUtils from "../../../utils/baseUtils/RouterUtils";

export default {
  name: "ForgotPasswordFormFinalStage",
  data() {
    return {
      formBody: {
        password: "",
        otp: "",
        mobileNumber: this.currentFormBody.mobileNumber,
      },
    };
  },
  computed: {
    ...mapState(["auth"]),
    currentFormBody() {
      return StoreUtils.rootGetters("form/getFormBody");
    },
  },
  methods: {
    submit() {
      StoreUtils.commit("form/BUILD_FORM_BODY", this.formBody);
      StoreUtils.dispatch("auth/forgotPassword");
    },
  },
  created() {
    // const mobileNumber = this.currentFormBody.mobileNumber;
    // StoreUtils.dispatch("auth/sendOTP", { mobileNumber });
  },
};
</script>

<style scoped></style>
