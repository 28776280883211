<template>
  <app-layout>
    <div class="feeds">
      <div class="columns">
        <div class="column is-3 is-hidden-mobile">
          <div class="merchant-ad">
            <p class="title">Become a Merchant Today</p>
            <p class="sub">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet
            </p>
            <button class="btn app-btn">Join</button>
          </div>
        </div>
        <div class="column is-6">
          <feeds-table />
        </div>
        <div class="column is-3 is-hidden-mobile">
          <suggestion-table />
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/app/layout/AppLayout";
import FeedsTable from "@/components/tables/post/FeedsTable";
import SuggestionTable from "@/components/tables/post/SuggestionTable";
import StoreUtils from "@/utils/baseUtils/StoreUtils";
export default {
  name: "PostSearch",
  components: { SuggestionTable, FeedsTable, AppLayout },
  methods: {
    searchForPosts() {
      StoreUtils.dispatch("post/searchForPosts", {
        keyword: StoreUtils.rootGetters("post/getCurrentSearchKeyword"),
      });
    },
    fetchFollowSuggestions() {
      StoreUtils.dispatch("post/fetchFollowSuggestions");
    },
  },
  created() {
    this.searchForPosts();
    this.fetchFollowSuggestions();
  },
};
</script>

<style scoped></style>
