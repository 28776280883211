<template>
  <div class="w-form">
    <FormulateForm @submit="submit">
      <FormulateInput
        name="mobileNumber"
        validation="required"
        placeholder="Phone Number"
        type="text"
      />
      <FormulateInput type="submit" label="Continue" />
    </FormulateForm>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StoreUtils from "../../../utils/baseUtils/StoreUtils";
import RouterUtils from "../../../utils/baseUtils/RouterUtils";

export default {
  name: "ForgotPasswordFormFirstStage",
  data() {
    return {
      formBody: {},
    };
  },
  computed: {
    ...mapState(["auth"]),
  },
  methods: {
    submit(data) {
      StoreUtils.commit("form/BUILD_FORM_BODY", data);
      StoreUtils.dispatch("auth/sendOTP");
    },
    routeToForgotPassword() {},
  },
  created() {
    StoreUtils.commit("form/SET_FORM_STAGE_TO", 0);
  },
};
</script>

<style scoped></style>
