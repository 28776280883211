<template>
  <div class="app-header">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand logo" href="#">
          <span>
            <img
              src="https://assets-base.s3.amazonaws.com/img/afropay/afropay-logo.png"
              alt="Logo"
              width="112"
              height="28"
            />
          </span>
          Afropays
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <a class="navbar-item" href="https://bulma.io/"> </a>
            <a class="navbar-item" href="https://bulma.io/"> </a>
            <a class="navbar-item" href="https://bulma.io/"> </a>
            <li class="nav-item">
              <a
                class="nav-link"
                aria-current="page"
                :class="{ active: currentRoute.includes('home') }"
                @click="switchRouteTo('home')"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: currentRoute.includes('post') }"
                @click="switchRouteTo('post-shop-feeds')"
                >Shop Feeds</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: currentRoute.includes('merchant') }"
                @click="switchRouteTo('merchant-all')"
                >Merchants</a
              >
            </li>
          </ul>

          <form class="d-flex ml-auto">
            <a class="nav-btn">
              <span>1</span>
              <svg
                viewBox="0 0 26 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.28613 1.42773L3.88613 1.87773L5.08988 16.219C5.18613 17.3902 6.16488 18.289 7.33988 18.2852H20.9761C22.0974 18.2877 23.0486 17.4627 23.2074 16.3527L24.3936 8.15523C24.5261 7.23898 23.8899 6.38898 22.9749 6.25648C22.8949 6.24523 4.30363 6.23898 4.30363 6.23898"
                  stroke="#0F4FAA"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <div class="flex dropdown">
              <a
                class="nav-btn dropdown-toggle"
                @click="switchRouteTo('login')"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M18.2 6.7L17.7 5.7C17.2 4.9 16.2 4.7 15.4 5.1V5.1C15 5.3 14.5 5.4 14.1 5.3 13.7 5.2 13.3 4.9 13.1 4.5 13 4.3 12.9 4 12.9 3.7V3.7C12.9 3.3 12.7 2.8 12.4 2.5 12.1 2.2 11.7 2 11.2 2H10.1C9.7 2 9.2 2.2 8.9 2.5 8.6 2.8 8.4 3.2 8.4 3.7V3.7C8.4 4.6 7.7 5.3 6.8 5.3 6.5 5.3 6.2 5.2 6 5.1V5.1C5.2 4.6 4.1 4.9 3.7 5.7L3.1 6.7C2.6 7.5 2.9 8.5 3.7 9V9C4.2 9.3 4.5 9.8 4.5 10.4 4.5 11 4.2 11.6 3.7 11.9V11.9C2.9 12.3 2.6 13.3 3.1 14.1V14.1L3.6 15.1C3.9 15.5 4.2 15.8 4.7 15.9 5.1 16 5.5 15.9 5.9 15.7V15.7C6.3 15.5 6.8 15.4 7.2 15.6 7.6 15.7 8 16 8.2 16.3 8.3 16.6 8.4 16.9 8.4 17.1V17.1C8.4 18.1 9.2 18.8 10.1 18.8H11.2C12.1 18.8 12.9 18.1 12.9 17.1V17.1C12.9 16.7 13 16.3 13.4 16 13.7 15.6 14.1 15.5 14.5 15.5 14.8 15.5 15.1 15.6 15.3 15.7V15.7C16.1 16.2 17.2 15.9 17.6 15.1V15.1L18.2 14.1C18.4 13.7 18.5 13.3 18.4 12.9 18.3 12.4 18 12.1 17.6 11.8V11.8C17.2 11.6 16.9 11.3 16.8 10.8 16.7 10.4 16.8 9.9 17 9.6 17.1 9.3 17.3 9.1 17.6 9V9C18.4 8.5 18.7 7.5 18.2 6.7V6.7 6.7Z"
                  />
                  <circle cx="10.7" cy="10.4" r="2.3" />
                </svg>
              </a>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" href="#"
                    ><span
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M18.2 6.7L17.7 5.7C17.2 4.9 16.2 4.7 15.4 5.1V5.1C15 5.3 14.5 5.4 14.1 5.3 13.7 5.2 13.3 4.9 13.1 4.5 13 4.3 12.9 4 12.9 3.7V3.7C12.9 3.3 12.7 2.8 12.4 2.5 12.1 2.2 11.7 2 11.2 2H10.1C9.7 2 9.2 2.2 8.9 2.5 8.6 2.8 8.4 3.2 8.4 3.7V3.7C8.4 4.6 7.7 5.3 6.8 5.3 6.5 5.3 6.2 5.2 6 5.1V5.1C5.2 4.6 4.1 4.9 3.7 5.7L3.1 6.7C2.6 7.5 2.9 8.5 3.7 9V9C4.2 9.3 4.5 9.8 4.5 10.4 4.5 11 4.2 11.6 3.7 11.9V11.9C2.9 12.3 2.6 13.3 3.1 14.1V14.1L3.6 15.1C3.9 15.5 4.2 15.8 4.7 15.9 5.1 16 5.5 15.9 5.9 15.7V15.7C6.3 15.5 6.8 15.4 7.2 15.6 7.6 15.7 8 16 8.2 16.3 8.3 16.6 8.4 16.9 8.4 17.1V17.1C8.4 18.1 9.2 18.8 10.1 18.8H11.2C12.1 18.8 12.9 18.1 12.9 17.1V17.1C12.9 16.7 13 16.3 13.4 16 13.7 15.6 14.1 15.5 14.5 15.5 14.8 15.5 15.1 15.6 15.3 15.7V15.7C16.1 16.2 17.2 15.9 17.6 15.1V15.1L18.2 14.1C18.4 13.7 18.5 13.3 18.4 12.9 18.3 12.4 18 12.1 17.6 11.8V11.8C17.2 11.6 16.9 11.3 16.8 10.8 16.7 10.4 16.8 9.9 17 9.6 17.1 9.3 17.3 9.1 17.6 9V9C18.4 8.5 18.7 7.5 18.2 6.7V6.7 6.7Z"
                        />
                        <circle cx="10.7" cy="10.4" r="2.3" /></svg></span
                    >Invoices</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><span
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M18.2 6.7L17.7 5.7C17.2 4.9 16.2 4.7 15.4 5.1V5.1C15 5.3 14.5 5.4 14.1 5.3 13.7 5.2 13.3 4.9 13.1 4.5 13 4.3 12.9 4 12.9 3.7V3.7C12.9 3.3 12.7 2.8 12.4 2.5 12.1 2.2 11.7 2 11.2 2H10.1C9.7 2 9.2 2.2 8.9 2.5 8.6 2.8 8.4 3.2 8.4 3.7V3.7C8.4 4.6 7.7 5.3 6.8 5.3 6.5 5.3 6.2 5.2 6 5.1V5.1C5.2 4.6 4.1 4.9 3.7 5.7L3.1 6.7C2.6 7.5 2.9 8.5 3.7 9V9C4.2 9.3 4.5 9.8 4.5 10.4 4.5 11 4.2 11.6 3.7 11.9V11.9C2.9 12.3 2.6 13.3 3.1 14.1V14.1L3.6 15.1C3.9 15.5 4.2 15.8 4.7 15.9 5.1 16 5.5 15.9 5.9 15.7V15.7C6.3 15.5 6.8 15.4 7.2 15.6 7.6 15.7 8 16 8.2 16.3 8.3 16.6 8.4 16.9 8.4 17.1V17.1C8.4 18.1 9.2 18.8 10.1 18.8H11.2C12.1 18.8 12.9 18.1 12.9 17.1V17.1C12.9 16.7 13 16.3 13.4 16 13.7 15.6 14.1 15.5 14.5 15.5 14.8 15.5 15.1 15.6 15.3 15.7V15.7C16.1 16.2 17.2 15.9 17.6 15.1V15.1L18.2 14.1C18.4 13.7 18.5 13.3 18.4 12.9 18.3 12.4 18 12.1 17.6 11.8V11.8C17.2 11.6 16.9 11.3 16.8 10.8 16.7 10.4 16.8 9.9 17 9.6 17.1 9.3 17.3 9.1 17.6 9V9C18.4 8.5 18.7 7.5 18.2 6.7V6.7 6.7Z"
                        />
                        <circle cx="10.7" cy="10.4" r="2.3" /></svg></span
                    >Settings</a
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><span
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M18.2 6.7L17.7 5.7C17.2 4.9 16.2 4.7 15.4 5.1V5.1C15 5.3 14.5 5.4 14.1 5.3 13.7 5.2 13.3 4.9 13.1 4.5 13 4.3 12.9 4 12.9 3.7V3.7C12.9 3.3 12.7 2.8 12.4 2.5 12.1 2.2 11.7 2 11.2 2H10.1C9.7 2 9.2 2.2 8.9 2.5 8.6 2.8 8.4 3.2 8.4 3.7V3.7C8.4 4.6 7.7 5.3 6.8 5.3 6.5 5.3 6.2 5.2 6 5.1V5.1C5.2 4.6 4.1 4.9 3.7 5.7L3.1 6.7C2.6 7.5 2.9 8.5 3.7 9V9C4.2 9.3 4.5 9.8 4.5 10.4 4.5 11 4.2 11.6 3.7 11.9V11.9C2.9 12.3 2.6 13.3 3.1 14.1V14.1L3.6 15.1C3.9 15.5 4.2 15.8 4.7 15.9 5.1 16 5.5 15.9 5.9 15.7V15.7C6.3 15.5 6.8 15.4 7.2 15.6 7.6 15.7 8 16 8.2 16.3 8.3 16.6 8.4 16.9 8.4 17.1V17.1C8.4 18.1 9.2 18.8 10.1 18.8H11.2C12.1 18.8 12.9 18.1 12.9 17.1V17.1C12.9 16.7 13 16.3 13.4 16 13.7 15.6 14.1 15.5 14.5 15.5 14.8 15.5 15.1 15.6 15.3 15.7V15.7C16.1 16.2 17.2 15.9 17.6 15.1V15.1L18.2 14.1C18.4 13.7 18.5 13.3 18.4 12.9 18.3 12.4 18 12.1 17.6 11.8V11.8C17.2 11.6 16.9 11.3 16.8 10.8 16.7 10.4 16.8 9.9 17 9.6 17.1 9.3 17.3 9.1 17.6 9V9C18.4 8.5 18.7 7.5 18.2 6.7V6.7 6.7Z"
                        />
                        <circle cx="10.7" cy="10.4" r="2.3" /></svg></span
                    >Logout</a
                  >
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import RouterUtils from "../../../utils/baseUtils/RouterUtils";
import StoreUtils from "../../../utils/baseUtils/StoreUtils";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "TheHeader",
  mixins: [clickaway],
  data() {
    return {
      isDropdownActive: false,
    };
  },
  computed: {
    currentRoute() {
      return StoreUtils.rootGetters("router/getActiveRoute");
    },
  },
  methods: {
    away() {
      this.isDropdownActive = false;
    },
    switchRouteTo(routeName) {
      RouterUtils.changeBaseRouteTo(routeName);
    },
    logOut() {
      StoreUtils.dispatch(StoreUtils.actions.auth.LOGOUT);
    },
  },
};
</script>

<style scoped></style>
