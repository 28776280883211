<template>
  <div id="app">
    <NotificationContainer />
    <AppLoading v-if="loader.blockingLoader" />

    <transition name="slide-fade" mode="out-in">
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationContainer from "./components/app/utility/notification/NotificationContainer";
import AppLoading from "./components/app/utility/loader/AppLoading";
import StoreUtils from "./utils/baseUtils/StoreUtils";

export default {
  name: "app",
  components: {
    NotificationContainer,
    AppLoading,
  },
  computed: {
    ...mapState(["loader"]),
    windowWidth() {
      return StoreUtils.rootGetters("sideMenu/getWindowWidth");
    },
  },
  mounted() {
    window.addEventListener("load", () => {
      // run after everything is in-place
      StoreUtils.commit("loader/SET_BLOCKING_LOADER", false);
    });

    window.addEventListener("resize", () => {
      // run after everything is in-place
      StoreUtils.commit("sideMenu/SET_WINDOW_WIDTH");
    });
  },
  created() {},
  watch: {
    windowWidth: function () {
      if (this.windowWidth === 768) {
        // console.log("The window width is 768px");
      }
    },
  },
};
</script>

<style>
a {
  cursor: pointer;
}
.slide-fade-enter {
  opacity: 0;
  transform: translateY(10px);
}
.slide-fade-enter-to {
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave {
}
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
</style>
