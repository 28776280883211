<template>
  <div class="footer-new wf-section">
    <div class="container footer">
      <div class="div-block-64">
        <div class="div-block-65">
          <div class="text-block-53">
            <a href="#" class="link-10">Afropays</a>
          </div>
          <div class="text-block-53">
            <a href="#" class="link-10">About</a>
          </div>
          <div class="text-block-53">
            <a href="#" class="link-10">Blog</a>
          </div>
          <div class="text-block-53">
            <a href="#" class="link-10">Help</a>
          </div>
          <div class="text-block-53">
            <a href="#" class="link-10">Privacy</a>
          </div>
          <div class="text-block-53">
            <a href="#" class="link-10">Terms</a>
          </div>
        </div>
        <div class="div-block-66">
          <div class="text-block-54">©2021 Afropays, Inc.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheNewFooter",
};
</script>

<style scoped></style>
