<template>
  <div class="profile-and-suggestions">
    <div class="name-block">
      <div class="div-block-15">
        <img src="~@/assets/images/Profile.svg" loading="lazy" alt="" />
      </div>
      <div>
        <div class="text-block-11">Adeyemi Okunola</div>
        <div>
          <a href="#" class="link-2">View profile</a>
        </div>
      </div>
    </div>
    <div class="suggestion-block">
      <div class="text-block-12">Suggestions for you</div>
      <div
        v-for="merchant in this.merchants"
        :key="merchant.merchantId"
        class="div-block-16"
      >
        <div class="div-block-17">
          <div class="div-block-18">
            <img width="25" height="25" :src="merchant.merchantPicture" loading="lazy" alt="" />
          </div>
          <div>
            <div class="text-block-13">
              <a
                href="#"
                class="link-4"
                :v-b-tooltip="merchant.merchantName"
                :title="merchant.merchantName"
                > {{ merchant.merchantName }}</a
              >
            </div>
            <div class="text-block-14">2.5k followers</div>
          </div>
        </div>
        <div>
          <div>
            <a href="#" class="link-3">Follow</a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="div-block-19">
        <div class="text-block-15">Home</div>
        <div class="text-block-15">About</div>
        <div class="text-block-15">Help</div>
        <div class="text-block-15">Privacy</div>
        <div class="text-block-15">Terms</div>
      </div>
      <div class="div-block-19">
        <div class="text-block-15">©2021 Afropays, Inc.</div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import { mapGetters } from "vuex";

export default {
  name: "SuggestionsCard",
  computed: {
    ...mapGetters({
      merchants: "merchant/getTopMerchants",
    })
  },
  created() {
    StoreUtils.dispatch("merchant/fetchTopMerchants");
  }
};
</script>

<style scoped></style>
