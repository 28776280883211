<template>
  <div class="add-friend-block transition-block">
    <img :src="data.merchantPicture" alt="" />
    <div class="page-meta">
      <span
        ><a @click="goToMerchantProfile(data)">{{ data.merchantName }}</a></span
      >
      <span>{{ data.merchantCategory }}</span>
    </div>
    <div class="add-friend add-transition">
      &#62;
<!--            <svg-->
<!--              xmlns="http://www.w3.org/2000/svg"-->
<!--              width="24"-->
<!--              height="24"-->
<!--              viewBox="0 0 24 24"-->
<!--              fill="none"-->
<!--              stroke="currentColor"-->
<!--              stroke-width="2"-->
<!--              stroke-linecap="round"-->
<!--              stroke-linejoin="round"-->
<!--              class="feather feather-user-plus"-->
<!--            >-->
<!--              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>-->
<!--              <circle cx="8.5" cy="7" r="4"></circle>-->
<!--              <line x1="20" y1="8" x2="20" y2="14"></line>-->
<!--              <line x1="23" y1="11" x2="17" y2="11"></line>-->
<!--            </svg>-->
    </div>
  </div>
</template>

<script>
import StoreUtils from "@/utils/baseUtils/StoreUtils";
import RouterUtils from "@/utils/baseUtils/RouterUtils";

export default {
  name: "FollowSuggestionCard",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToMerchantProfile(merchant) {
      StoreUtils.commit("merchant/SET_CURRENT_MERCHANT", merchant);

      RouterUtils.changeRouteUrlTo(`/merchant/profile/${merchant.merchantId}`);
    },
  },
};
</script>

<style scoped></style>
